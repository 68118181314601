import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ACHDialogModal = ({ show, onHide, setACHChoice }) => {
  return (
    <Modal id="ACH-Dialog-Modal" show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Choose Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Choose a payment method below. ACH payments include a 1% fee. Credit
          card payments include a 3% fee.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setACHChoice(true)}>
          ACH
        </Button>
        <Button variant="primary" onClick={() => setACHChoice(false)}>
          Credit Card
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ACHDialogModal;

import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';

import InfiniteScroll from 'react-infinite-scroll-component';
import useMediaQuery from "@mui/material/useMediaQuery";
import { compareAsc } from 'date-fns';

import './InvoiceHistory.scss';

import { getInvoices } from '../../apis/invoicesApi';
import { getProcessingPayments, expireProcessingPayments, expireProcessingPaymentsByInvoice } from '../../apis/paymentsApi';
import { PAGES } from '../search-filters-input/SearchFiltersDesktop';

import BulkPaymentsModal from '../../components/bulk-payments-modal/BulkPaymentsModal';
import CollapseableCardSection from '../../components/styled-components/collapseable-card-section/CollapseableCardSection';
import InvoiceHistoryCard from '../../components/invoice-history-card/InvoiceHistoryCard';
import InvoiceHistoryDesktop from "./InvoiceHistoryDesktop";
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import SearchFiltersModal from '../../components/search-filters-modal/SearchFiltersModal';
import SearchFiltersInput from '../../components/search-filters-input/SearchFiltersInput';

import { AuthContext } from '../../providers/authProvider';
import { PaymentContext } from '../../providers/paymentProvider';

import { augmentInvoice, isProcessingPaymentExpired } from '../../utils/invoiceFormatter';
import { prettyPrice } from '../../utils/numberFormatter';
import {MOBILE_SCREEN_MAX_WIDTH} from "../../constants/miscConstants";


export default function InvoiceHistory() {
    const { token } = useContext(AuthContext);
    const { newProcessingInvoices } = useContext(PaymentContext);
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_SCREEN_MAX_WIDTH}px)`, {
        noSsr: true
    });

    const initUnfilteredPendingState = {
        invoices: [],
        totalDue: undefined,
        totalOverdue: undefined,
        totalProcessing: undefined,
        totalUnderThirtyDays: undefined,
        totalBetweenThirtyOneAndSixtyDays: undefined,
        totalBetweenSixtyOneAndNinetyDays: undefined,
        totalOverNinetyDays: undefined,
        isLoading: true,
    };

    const initPendingState = {
        invoices: [],
        processingInvoices: [], // Payment has been initiated 
        isLoading: true,
        hasError: false,
        filters: {
            dateRange: {},
            plantId: undefined,
            customer: undefined,
        }
    };

    const initPaidState = {
        invoices: [],
        offset: 0,
        pageSize: 10,
        isLoading: false,
        isFirstPageLoaded: false,
        isLastPageLoaded: false,
        hasError: false,
        filters: {
            dateRange: {},
            plantId: undefined,
        }
    }

    const initProcessingPaymentsState = {
        payments: [],
        isLoading: true,
        hasError: false,
    }

    const [pendingInvoices, setPendingInvoices] = useState(initPendingState);
    const [paidInvoices, setPaidInvoices] = useState(initPaidState);
    const [processingPayments, setProcessingPayments] = useState(initProcessingPaymentsState);
    const [unfilteredPendingInvoices, setUnfilteredPendingInvoices] = useState(initUnfilteredPendingState);

    const [searchTerm, setSearchTerm] = useState();

    const [filterModal, setFilterModal] = useState({ show: false });
    const handleFiltersClose = () => setFilterModal({ show: false });
    const handleFiltersShow = () => setFilterModal({ show: true });

    const [bulkPaymentModal, setBulkPaymentModal] = useState({ show: false });
    const handleBulkPaymentClose = () => setBulkPaymentModal({ show: false });
    const handleBulkPaymentShow = () => setBulkPaymentModal({ show: true });

    const [recentlyProcessedAlert, setRecentlyProcessedAlert] = useState({ show: false, invoices: [] });
    const handleProcessedAlertDismiss = () => setRecentlyProcessedAlert({ show: false });

    useEffect(() => {
        if (processingPayments.isLoading) {
            fetchProcessingPayments();
        }
    }, [processingPayments.isLoading]);

    useEffect(() => {
        if (!processingPayments.isLoading && pendingInvoices.isLoading) {
            fetchAllPendingInvoices();
        }
    }, [pendingInvoices.isLoading, processingPayments.isLoading]);

    useEffect(() => {
        if (!processingPayments.isLoading && !paidInvoices.isFirstPageLoaded && isMobile) {
            fetchPaidInvoices();
        }
    }, [paidInvoices.isFirstPageLoaded, processingPayments.isLoading, isMobile]);

    useEffect(() => {
        // TODO: Update state more granularly, rather than refreshing.
        if ((newProcessingInvoices || []).length > 0) {
            window.location.reload(false);
        }
    }, [newProcessingInvoices]);

    //
    // API Calls
    //

    function fetchProcessingPayments() {
        getProcessingPayments({
            token,
        })
            .then(handleProcessingPaymentsSuccess, handleProcessingPaymentsError)
            .catch(handleProcessingPaymentsError);
    }

    const handleProcessingPaymentsSuccess = (res) => {
        res.forEach((payment) => {
            if (isProcessingPaymentExpired(payment)) {
                // If we find any expired payments, send a call to the API to clear them. 
                payment.isExpired = true;
                expireProcessingPayments({ payment, token });
            }
        })

        setProcessingPayments({
            payments: res,
            isLoading: false,
        });
    }

    const handleProcessingPaymentsError = (err) => {
        setProcessingPayments({
            ...processingPayments,
            isLoading: false,
            hasError: true,
        });
    }

    function fetchAllPendingInvoices() {
        const { filters } = pendingInvoices;
        const { dateRange, plantId } = filters;
        const { startDate, endDate } = dateRange;

        getInvoices({
            status: 'pending',
            startDate: startDate?.toLocaleDateString() || undefined,
            endDate: endDate?.toLocaleDateString() || undefined,
            searchTerm,
            plantId,
            limit: 1000,
            offset: 0,
            token,
        })
            .then(handlePendingInvoiceSuccess, handlePendingInvoiceError)
            .catch(handlePendingInvoiceError);
    }

    const handlePendingInvoiceSuccess = (res) => {
        // Pending invoices fall into three categories: 
        // 1. Due -- These invoices are unpaid.
        // 2. Over Due -- These invoices are unpaid and past their due date. 
        // 3. Processing -- The user has initiated a payment, but it has not been processed. 
        // We display these items differently in the UI. 
        const invoices = [];
        const processingInvoices = [];

        let totalDue = 0, totalOverdue = 0, totalProcessing = 0, totalUnderThirtyDays = 0, totalBetweenThirtyOneAndSixtyDays = 0, totalBetweenSixtyOneAndNinetyDays = 0, totalOverNinetyDays = 0;

        res.forEach((invoice) => {
            // Augment the invoice with calculated fields (e.g. isProcessing)
            augmentInvoice(invoice, processingPayments.payments);

            totalDue += invoice.total_amount_due;

            if(invoice.daysOld <= 30) {
                totalUnderThirtyDays += invoice.total_amount_due;
            } else if (invoice.daysOld < 60) {
                totalBetweenThirtyOneAndSixtyDays += invoice.total_amount_due;
            } else if(invoice.daysOld < 90) {
                totalBetweenSixtyOneAndNinetyDays += invoice.total_amount_due;
            } else {
                totalOverNinetyDays += invoice.total_amount_due;
            }

            if (invoice.isProcessing) {
                totalProcessing += invoice.total_amount_due;
                processingInvoices.push(invoice);
            } else if (invoice.isOverdue) {
                totalOverdue += invoice.total_amount_due;
                invoices.push(invoice);
            } else {
                invoices.push(invoice);
            }
        })

        // Sort pending invoices in reverse chronological order.
        invoices.sort((i1, i2) => compareAsc(new Date(i1.date), new Date(i2.date)));

        // If this is the first call to fetch pending invoices, 
        // store the initial, unfiltered list separately.
        if (unfilteredPendingInvoices.invoices.length === 0) {
            setUnfilteredPendingInvoices({
                invoices,
                totalDue,
                totalOverdue,
                totalProcessing,
                totalUnderThirtyDays,
                totalBetweenThirtyOneAndSixtyDays,
                totalBetweenSixtyOneAndNinetyDays,
                totalOverNinetyDays,
                isLoading: false,
            })
        }

        setPendingInvoices({
            ...pendingInvoices,
            invoices,
            processingInvoices,
            isLoading: false,
        });
    }

    const handlePendingInvoiceError = (err) => {
        setPendingInvoices({
            ...pendingInvoices,
            isLoading: false,
            hasError: true,
        });
    }

    function fetchPaidInvoices() {
        const { isLoading, isLastPageLoaded, offset, pageSize, filters } = paidInvoices;
        const { dateRange, plantId } = filters;
        const { startDate, endDate } = dateRange;

        if (!isLoading && !isLastPageLoaded) {
            getInvoices({
                status: 'paid',
                startDate: startDate?.toLocaleDateString() || undefined,
                endDate: endDate?.toLocaleDateString() || undefined,
                searchTerm,
                plantId,
                limit: pageSize,
                offset,
                token,
            })
                .then(handlePaidInvoiceSuccess, handlePaidInvoiceError)
                .catch(handlePaidInvoiceError);
        }
    }

    const handlePaidInvoiceSuccess = (res) => {
        // If we find a paid invoice with an associated processing payment, 
        // expire the processing payment. 
        const recentlyProcessedInvoices = [];
        res.forEach((invoice) => {
            const hasAssociatedPayment = processingPayments.payments.some((payment) => {
                return payment.invoice_id === invoice.invoice_number;
            });

            if (hasAssociatedPayment) {
                recentlyProcessedInvoices.push(invoice);
                expireProcessingPaymentsByInvoice({ invoice, token });
            }
        })

        if (recentlyProcessedInvoices.length) {
            const { invoices } = recentlyProcessedAlert;
            setRecentlyProcessedAlert({ show: true, invoices: invoices.concat(recentlyProcessedInvoices) });
        }

        setPaidInvoices({
            ...paidInvoices,
            invoices: paidInvoices.invoices.concat(res),
            offset: paidInvoices.offset + res.length,
            isLoading: false,
            isFirstPageLoaded: true,
            isLastPageLoaded: res.length < paidInvoices.pageSize
        });
    }

    const handlePaidInvoiceError = (err) => {
        setPaidInvoices({
            ...paidInvoices,
            isLoading: false,
            hasError: true,
        });
    }

    //
    // Private Helpers
    //

    function resetInvoiceHistory() {
        setPendingInvoices(initPendingState);
        setPaidInvoices(initPaidState);
    }

    const setFilters = (filters) => {
        handleFiltersClose();

        setPendingInvoices({ ...initPendingState, filters });
        setPaidInvoices({ ...initPaidState, filters });
    }

    const resetFilters = () => {
        handleFiltersClose();

        resetInvoiceHistory();
    }

    const handleSearchInvoices = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setPendingInvoices({ ...initPendingState, filters: pendingInvoices.filters });
        setPaidInvoices({ ...initPaidState, filters: paidInvoices.filters });
    }

    function hasFilters() {
        // We use the same filters between pending and paid invoices, 
        // so we can use either to check for the existance of filters.
        const { filters } = pendingInvoices;
        const { dateRange, plantId } = filters;

        return !!dateRange.startDate || !!plantId;
    }

    function hasPendingPayment() {
        return unfilteredPendingInvoices.invoices.filter((invoice) => {
            return !invoice.isProcessing;
        }).length > 0;
    }

    //
    // Render Helpers  
    //

    function renderProcessedInvoiceAlert() {
        const { invoices = [], show } = recentlyProcessedAlert;
        return (
            show &&
            <Alert className="mx-3" variant={"info"} onClose={handleProcessedAlertDismiss} dismissible>
                The following invoices were recently processed:&nbsp;
                {invoices.map((invoice, i) => {
                    const isLastInvoice = (i === invoices.length - 1);
                    return (
                        <>
                            <a href={`/invoices/${invoice.invoice_id}`} target="_blank">{invoice.invoice_number}</a>
                            {isLastInvoice || <span>,&nbsp;</span>}
                        </>
                    )
                })}
            </Alert>
        )
    }

    function renderCard(invoice, status) {
        return (
            <InvoiceHistoryCard
                invoice={invoice}
                status={status}
                key={invoice.invoice_id}
            />
        );
    }

    function renderTotalDue() {
        const hasProcessingBalance = unfilteredPendingInvoices.totalProcessing > 0;
        const hasOverdueBalance = unfilteredPendingInvoices.totalOverdue > 0;

        return (
            <Card className="mx-3 mb-3">
                <Card.Body>
                    <div className="d-flex">
                        <div className="col-7">
                            <h6 className="text-muted">TOTAL BALANCE DUE</h6>
                            <h1>
                                <strong>{prettyPrice(unfilteredPendingInvoices.totalDue)}</strong>
                            </h1>
                            {
                                hasPendingPayment() &&
                                <Button className="pay-invoices-button" variant="outline-primary" size="sm" onClick={handleBulkPaymentShow}>Pay Invoices</Button>
                            }
                        </div>
                        <div className="col-5">
                            <div>
                                <h6 className={(hasProcessingBalance ? "text-primary" : "text-muted") + " mb-1"}>
                                    Payment Pending
                                </h6>
                                <h5 className={hasProcessingBalance ? "" : "text-muted"}>
                                    <strong>{prettyPrice(unfilteredPendingInvoices.totalProcessing)}</strong>
                                </h5>
                            </div>
                            <div>
                                <h6 className={(hasOverdueBalance ? "text-danger" : "text-muted") + " mb-1"}>
                                    Balance Past Due
                                </h6>
                                <h5 className={hasOverdueBalance ? "" : "text-muted"}>
                                    <strong>{prettyPrice(unfilteredPendingInvoices.totalOverdue)}</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        );
    }

    function renderBulkPaymentModal() {
        // Exclude processing invoices from the bulk payment workflow
        const invoices = unfilteredPendingInvoices.invoices.filter((invoice) => !invoice.isProcessing);

        return (
            <BulkPaymentsModal
                pendingInvoices={invoices}
                show={bulkPaymentModal.show}
                onHide={handleBulkPaymentClose}
            ></BulkPaymentsModal>
        );
    }

    function renderSearchBar() {
        return (
            <SearchFiltersInput
                searchTerm={searchTerm}
                onSearchSubmit={handleSearchInvoices}
                onSetSearchTerm={setSearchTerm}
                onShowFilters={handleFiltersShow}
                onResetFilters={resetFilters}
                hasFilters={hasFilters}
                inputLabel="Search for invoices"
                inputPlaceholder="Search by invoice, po, or order"
            ></SearchFiltersInput>
        )
    }

    function renderFilterModal() {
        return (
            <SearchFiltersModal
                initFilters={pendingInvoices.filters}
                show={filterModal.show}
                onHide={handleFiltersClose}
                onApplyFilters={setFilters}
                onClearFilters={resetFilters}
                title="Filter Invoices"
                page={PAGES.Invoices}
            ></SearchFiltersModal>
        );
    }

    function renderInfiniteScrollingPaidInvoices() {
        const { invoices, isLastPageLoaded } = paidInvoices;
        return (
            <InfiniteScroll
                dataLength={invoices.length} //This is important field to render the next data
                next={fetchPaidInvoices}
                hasMore={!isLastPageLoaded}
                loader={<div><LoadingSpinner typ="infinite-scroll" />&nbsp;</div>}
                scrollableTarget="Dashboard-Body"
                endMessage={
                    <p className="text-center">
                        <b>No More Results</b>
                    </p>
                }
            >
                {paidInvoices.invoices.map(i => renderCard(i, 'paid'))}
            </InfiniteScroll>
        );
    }

    function renderInvoiceHistory() {
        return (
            <>
                <div id="Invoice-History" className="d-md-none">
                    {renderTotalDue()}

                    {renderSearchBar()}
                    {renderFilterModal()}

                    {renderProcessedInvoiceAlert()}

                    {renderBulkPaymentModal()}

                    {(pendingInvoices.isLoading || !paidInvoices.isFirstPageLoaded) ?
                        <LoadingSpinner typ="page" /> :
                        <div className="mt-3">
                            <CollapseableCardSection title="Payment Due" count={pendingInvoices.invoices.length}>
                                {pendingInvoices.invoices.map(i => renderCard(i, 'pending'))}
                            </CollapseableCardSection>

                            <CollapseableCardSection title="Payment Pending" count={pendingInvoices.processingInvoices.length}>
                                {pendingInvoices.processingInvoices.map(i => renderCard(i, 'pending'))}
                            </CollapseableCardSection>

                            <CollapseableCardSection title="Paid Invoices">
                                {renderInfiniteScrollingPaidInvoices()}
                            </CollapseableCardSection>
                        </div>
                    }
                </div>
            </>
        );
    }

    return (
        <>
            { isMobile ? (
                <>
                    {(unfilteredPendingInvoices.isLoading) ?
                        <div><LoadingSpinner typ="page" /></div> :
                        renderInvoiceHistory()
                    }
                </>
            ) : (
                <div style={{height: "100%"}}>
                    <InvoiceHistoryDesktop
                        processingPayments={processingPayments}
                        onBulkPaymentsClick={handleBulkPaymentShow}
                    />
                    {renderBulkPaymentModal()}
                </div>
            )}
        </>
    )
};

import React, { useContext, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import {
    Container,
    Nav,
    Navbar,
    Offcanvas
} from 'react-bootstrap';

import './Navigation.scss';

import { AuthContext } from '../../providers/authProvider';

import { getFullName } from '../../utils/userFormatter';

export default function Navigation() {
    const [showMenu, setShowMenu] = useState(false);

    const { token, authorizedUser, isSuperuserSession, logout } = useContext(AuthContext);
    const history = useHistory();

    const handleCloseMenu = () => setShowMenu(false);
    const handleOpenMenu = () => setShowMenu(true);

    const handleLogout = () => {
        logout().then(
            (res) => {
                // Once the user has successfully logged out, 
                // redirect them to the login page.
                history.push('/login');
            }
        );
    }

    function CustomNavLink({ route, ...props }) {
        return (
            <NavLink
                className="custom-nav-link"
                to={route}
                onClick={handleCloseMenu}>
                {props.children}
            </NavLink>
        );
    }

    function renderLoggedInNavbar() {
        return (
            <Navbar
                id="Navigation"
                expand="lg"
                fixed="top"
                className={(isSuperuserSession ? "superuser-session" : "") + " no-print"}
            >
                <Container className="justify-content">
                    {isSuperuserSession && !!authorizedUser &&
                        <div id="superuser-banner" className="col-12 bg-warning fixed-top text-center">
                            <i className="bi bi-exclamation-triangle" />&nbsp;
                            {`Logged in as ${getFullName(authorizedUser)}`}
                        </div>
                    }
                    <Navbar.Brand href="/">
                        <img id="nav-logo" src="/msc-logo.png" />

                    </Navbar.Brand>
                    <Nav className="dashboard-title">
                        <Nav.Item>
                            <h1>Dashboard</h1>
                        </Nav.Item>
                    </Nav>
                    <Nav>
                        <Nav.Item>
                            <i
                                onClick={handleOpenMenu}
                                className="bi bi-list"
                                role="img"
                                aria-label="Navigation Menu"
                            />
                        </Nav.Item>
                    </Nav>
                </Container>

                <Offcanvas id="Navigation-Offcanvas" show={showMenu} onHide={handleCloseMenu} placement="end">
                    <Offcanvas.Body className="bg-secondary">
                        <Nav className="flex-column">
                            <Nav.Item>
                                <CustomNavLink route="/invoices">Invoices</CustomNavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <CustomNavLink route="/tickets">Tickets</CustomNavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <CustomNavLink route="/settings/account-management">Account Settings</CustomNavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <CustomNavLink route="/contact-us">Contact Us</CustomNavLink>
                            </Nav.Item>
                            <hr />
                            <Nav.Item>
                                <a className="custom-nav-link" onClick={handleLogout}>Log Out</a>
                            </Nav.Item>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </Navbar>
        );
    }

    function renderLoggedOutNavbar() {
        return (
            <Navbar id="Navigation" expand="lg" fixed="top">
                <Container className="justify-content-center">
                    <Navbar.Brand>
                        <img id="nav-logo-full" src="/msc-logo-full.png" />
                    </Navbar.Brand>
                </Container>
            </Navbar>
        );
    }

    return (
        <>
            {!!token ? renderLoggedInNavbar() : renderLoggedOutNavbar()}
        </>
    )
}

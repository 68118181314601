import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Card, Modal, Spinner} from 'react-bootstrap';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Divider from "@mui/material/Divider";
import Drawer from '@mui/material/Drawer';
import {IconButton} from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';

import {downloadInvoice, downloadStatement, getDesktopInvoices, getInvoiceRowCount} from "../../apis/invoicesApi";
import {getPlantName} from '../../constants/plants';
import {intervalDateRanges} from "../../utils/dateFormatter";
import {prettyNumber, prettyPrice} from '../../utils/numberFormatter';
import InvoiceStatus from '../styled-components/invoice-status/InvoiceStatus';
import InvoiceDetail from '../invoice-detail/InvoiceDetail';
import SearchFiltersDesktop, {PAGES} from '../search-filters-input/SearchFiltersDesktop';
import PaymentFormButton from "../payment-form-button/PaymentFormButton";
import {AuthContext} from "../../providers/authProvider";
import {augmentInvoice} from "../../utils/invoiceFormatter";
import {formatArrayFilter} from "../../utils/miscUtils";
import BulkPaymentsModal from "../bulk-payments-modal/BulkPaymentsModal";
import TicketDetail from "../ticket-detail/TicketDetail";
import { PlantsContext } from '../../providers/plantsProvider';


const invoiceToRowMap = (invoice, status, plantsList) => {
    let date = new Date(invoice.date);
    let dateDue = new Date(invoice.due_date)
    return {
        id: invoice.invoice_id,
        plant: getPlantName(invoice.plant_id, plantsList),
        formattedDate: date.toLocaleDateString('en-US'),
        customer: invoice.customer_name + ' (#' + invoice.customer_id + ')',
        order: invoice.order_description_1 + ' (#' + invoice.order_id + ')',
        po: invoice.po_number || 'N/A',
        dateDue: dateDue.toLocaleDateString('en-US'),
        amountDue: prettyPrice(status.toUpperCase() === 'PAID' ? 0 : invoice.total_amount_due),
        invoiceStatus: status,
        ...invoice
    }
}

const filtersInitialState = {
    status: 'DUE',
    startDate: undefined,
    endDate: undefined,
    customers: undefined,
    plants: undefined,
    search: undefined
}

const initialInvoiceAggregations = {
    totalDue: 0,
    totalOverdue: 0,
    totalUnderThirtyDays: 0,
    totalBetweenThirtyOneAndSixtyDays: 0,
    totalBetweenSixtyOneAndNinetyDays: 0,
    totalOverNinetyDays: 0
}

const InvoiceHistoryDesktop = ({processingPayments}) => {
    const { token } = useContext(AuthContext);
    const {plants: plantsList } = useContext(PlantsContext);

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [invoiceDetail, setInvoiceDetail] = useState(null);
    const [invoiceAggregations, setInvoiceAggregations] = useState(initialInvoiceAggregations);
    const [totalProcessing, setTotalProcessing] = useState(0);
    const [selectionModel, setSelectionModel] = useState([]);
    const [pageSize, setPageSize] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isRowCountLoading, setIsRowCountLoading] = useState(true);
    const [bulkPaymentsModalOpen, setBulkPaymentsModalOpen] = useState(false);
    const [bulkPaymentsInvoices, setBulkPaymentsInvoices] = useState([]);
    const [bulkPaymentsLoading, setBulkPaymentsLoading] = useState(false);

    const [ticketDetail, setTicketDetail] = useState();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [statementLoading, setStatementLoading] = useState(false);

    const [filters, setFilters] = useState(filtersInitialState);
    const [selectedDateRangeCategory, setSelectedDateRangeCategory] = useState(null);

    const [sortModel, setSortModel] = useState([]);
    const [orderBy, setOrderBy] = useState(undefined);
    const [sortOrder, setSortOrder] = useState(undefined);

    const [invoices, setInvoices] = useState([]);

    const cancelSignal = axios.CancelToken.source();
    const drawerWidth = 400;

    const handleDownloadInvoice = (invoiceId) => {
        setDownloadLoading(true);
        downloadInvoice({invoiceId, token}).then(() => setDownloadLoading(false));
    }

    const handleDownloadStatement = () => {
        setStatementLoading(true);
        downloadStatement({token}).then(() => setStatementLoading(false));
    }

    useEffect(() => {
        let isMounted = true;
        setIsRowCountLoading(true);
        getInvoiceRowCount({
            status: filters.status || undefined,
            startDate: filters.startDate || undefined,
            endDate: filters.endDate || undefined,
            searchTerm: filters.search || undefined,
            plants: filters.plants ? formatArrayFilter(filters.plants) : undefined,
            customers: filters.customers ? formatArrayFilter(filters.customers) : undefined,
            orders: filters.orders ? formatArrayFilter(filters.orders) : undefined,
            token: token,
            cancelToken: cancelSignal.token
        }).then(res => {
            if(isMounted && res) {
                setRowCount(res.count);
                setInvoiceAggregations({
                    totalDue: res.totalDue,
                    totalOverdue: res.totalOverdue,
                    totalUnderThirtyDays: res.lessThanThirtyOne,
                    totalBetweenThirtyOneAndSixtyDays: res.betweenThirtyOneAndSixty,
                    totalBetweenSixtyOneAndNinetyDays: res.betweenSixtyOneAndNinety,
                    totalOverNinetyDays: res.overNinety
                })
                setIsRowCountLoading(false);
            }
        });

        // get aggregations for processing payments
        let processingInvoices = processingPayments?.payments ? processingPayments.payments.map(payment => `${payment.plant_id}-${payment.invoice_id}`) : [];
        if(processingInvoices.length > 0) {
            getInvoiceRowCount({
                status: filters.status || undefined,
                startDate: filters.startDate || undefined,
                endDate: filters.endDate || undefined,
                searchTerm: filters.search || undefined,
                plants: filters.plants ? formatArrayFilter(filters.plants) : undefined,
                customers: filters.customers ? formatArrayFilter(filters.customers) : undefined,
                orders: filters.orders ? formatArrayFilter(filters.orders) : undefined,
                invoice_numbers: processingInvoices ? formatArrayFilter(processingInvoices) : undefined,
                token: token,
                cancelToken: cancelSignal.token
            }).then(res => {
                if(isMounted && res) {
                    setTotalProcessing(res.totalDue);
                }
            });
        } else {
            setTotalProcessing(0);
        }

        setBulkPaymentsLoading(true);
        if(filters.status === 'PAID') {
            setBulkPaymentsInvoices([]);
            setBulkPaymentsLoading(false);
        } else {
            getDesktopInvoices({
                status: 'DUE',
                startDate: filters.startDate || undefined,
                endDate: filters.endDate || undefined,
                searchTerm: filters.search || undefined,
                plants: filters.plants ? formatArrayFilter(filters.plants) : undefined,
                customers: filters.customers ? formatArrayFilter(filters.customers) : undefined,
                orders: filters.orders ? formatArrayFilter(filters.orders) : undefined,
                limit: 10000,
                offset: 0,
                token: token,
                cancelToken: cancelSignal.token
            }).then((res) => {
                if(isMounted && res) {
                    setBulkPaymentsInvoices(res.map(invoice => {
                        augmentInvoice(invoice, processingPayments.payments);
                        return invoice;
                    }).filter(invoice => !invoice.isProcessing && invoice.total_amount_due > 0 && invoice.isPending));
                    setBulkPaymentsLoading(false);
                }

            }).catch();
        }


        return () => {
            isMounted = false;
            cancelSignal.cancel();
        }
    }, [filters, processingPayments]);


    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        setRows([]);
        getDesktopInvoices({
            status: filters.status || undefined,
            startDate: filters.startDate || undefined,
            endDate: filters.endDate || undefined,
            searchTerm: filters.search || undefined,
            plants: filters.plants ? formatArrayFilter(filters.plants) : undefined,
            customers: filters.customers ? formatArrayFilter(filters.customers) : undefined,
            orders: filters.orders ? formatArrayFilter(filters.orders) : undefined,
            limit: pageSize,
            offset: pageNumber * pageSize,
            orderBy,
            sortOrder,
            token: token,
            cancelToken: cancelSignal.token
        }).then(res => {
            if(isMounted && res) {
                setRows(res.map(invoice => {
                    augmentInvoice(invoice, processingPayments.payments);
                    return invoiceToRowMap(invoice, invoice.invoice_status, plantsList);
                }));
                setIsLoading(false);
            }
        }).catch();

        return () => {
            isMounted = false;
            cancelSignal.cancel();
        }
    }, [pageNumber, pageSize, processingPayments, orderBy, sortOrder, filters]);

    const handleTicketClick = (ticket) => {
        ticket.id = `${ticket.plant_id}-${ticket.ticket_number}-${ticket.unique_id}`;
        setTicketDetail(ticket);
    }

    const columns = [
        {
            field: 'invoice_number',
            headerName: 'Invoice #',
        },
        {
            field: 'plant',
            headerName: 'Plant',
            width: 150,
        },
        {
            field: 'formattedDate',
            headerName: 'Date',
        },
        {
            field: 'customer',
            headerName: 'Customer',
            width: 150,
        },
        {
            field: 'order',
            headerName: 'Order',
        },
        {
            field: 'po',
            headerName: 'PO Number',
        },
        {
            field: 'tickets',
            headerName: 'Tickets',
            flex: true,
            sortable: false,
            cellClassName: 'nowrap',
            renderCell: (params) => {
                const allInvoiceProducts = (params.row.products || []);
                const topInvoiceProducts = allInvoiceProducts.slice(0, 3);
                const remainingProductsCount = allInvoiceProducts.length - topInvoiceProducts.length;

                return (
                    <div>
                        <strong className="products-count text-muted fs-7 mb-1">
                            {params.row.total_tickets} Tickets | {params.row.total_products} Products
                        </strong>
                        <div className="mb-1">
                        {
                            topInvoiceProducts.map((product, i) => {
                                return (
                                    <p key={`product-${i}`} className="product-line">
                                        {product.ticket_count} x {product.product_description}
                                        &nbsp;
                                        <span className="text-muted">({prettyNumber(product.product_quantity)} T)</span>
                                    </p>
                                )
                            })
                        }
                        </div>
                        {
                            !!remainingProductsCount &&
                            <div className="text-muted fs-7">
                                +{remainingProductsCount} more
                            </div>
                        }
                    </div>
                )
            }
        },
        {
            field: 'dateDue',
            headerName: 'Due Date',
        },
        {
            field: 'amountDue',
            headerName: 'Amount Due',
        },
        {
            field: 'invoiceStatus',
            headerName: 'Status',
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return <InvoiceStatus invoice={params.row} />
            }
        },
        {
            field: 'payInvoice',
            headerName: '',
            renderCell: (params) => {
                return (
                    <>
                    {
                        (params.row.invoiceStatus === "DUE" || params.row.invoiceStatus === "PROCESSING") ?
                            (
                                <PaymentFormButton
                                    invoices={[params.row]}
                                    buttonText={"Pay Invoice"}
                                    buttonSize={""}
                                    buttonVariant={"primary"}
                                />
                            )
                            :
                            (
                                <></>
                            )
                    }
                    </>
                )
            },
            align: "center",
            sortable: false,
        }
    ];

    const handleSortModelChange = (model) => {
        if(model.length > 0) {
            setOrderBy(model[0].field);
            setSortOrder(model[0].sort.toUpperCase())
        } else {
            setOrderBy(undefined);
            setSortOrder(undefined);
        }
        setSortModel(model);
        setInvoiceDetail(null);
        setPageNumber(0);
    };

    const onBulkPaymentsClick = () => {
        setBulkPaymentsModalOpen(true);
    }

    const renderBulkPayments = () => {
        return (
            <BulkPaymentsModal
                pendingInvoices={bulkPaymentsInvoices}
                show={bulkPaymentsModalOpen}
                onHide={() => setBulkPaymentsModalOpen(false)}
            />
        );
    }

    return (
        <>
            <div className={'main ' + (!!invoiceDetail ? 'drawer-open': '') + ' d-flex flex-row'} style={{height: "100%"}}>
                <div style={{minWidth: 300, maxWidth: 300}}>
                    <SearchFiltersDesktop
                        page={PAGES.Invoices}
                        setFilters={setFilters}
                        selectedDateRangeCategory={selectedDateRangeCategory}
                        setSelectedDateRangeCategory={setSelectedDateRangeCategory}
                    />
                </div>
                <Divider orientation="vertical" flexItem style={{height: "100%"}}/>
                <div className="d-flex flex-column p-2" style={{width: "100%"}}>
                    <Card className="mb-3">
                        <Card.Body>
                            {isRowCountLoading ? <LoadingSpinner typ="infinite-scroll" /> : (
                                <div className="d-flex flex-row justify-content-between">
                                <div>
                                    <h5 className="text-muted text-black-50">Total Balance Due</h5>
                                    <h3>{prettyPrice(invoiceAggregations.totalDue)}</h3>
                                </div>
                                <div>
                                    <h5 className="text-muted">Payment Pending</h5>
                                    <h3>{prettyPrice(totalProcessing)}</h3>
                                </div>
                                <div>
                                    <h5 className="text-muted">Balance Past Due</h5>
                                    <h3 className="text-danger">{prettyPrice(invoiceAggregations.totalOverdue)}</h3>
                                </div>
                                { !!invoiceDetail ? (
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="flex-column mx-3">
                                            <h6 className="text-muted"><a className="interval" onClick={() => setSelectedDateRangeCategory(intervalDateRanges.lessThanThirty)}>&lt;= 30 Days</a></h6>
                                            <h6 className="text-muted"><a className="interval" onClick={() => setSelectedDateRangeCategory(intervalDateRanges.thirtyOneToSixty)}>31-60 Days</a></h6>
                                            <h6 className="text-muted"><a className="interval" onClick={() => setSelectedDateRangeCategory(intervalDateRanges.sixtyOneToNinety)}>61-90 Days</a></h6>
                                            <h6 className="text-muted"><a className="interval" onClick={() => setSelectedDateRangeCategory(intervalDateRanges.overNinety)}>Over 90 Days</a></h6>
                                        </div>
                                        <div className="flex-column">
                                            <h6 className="text-muted text-right">{prettyPrice(invoiceAggregations.totalUnderThirtyDays)}</h6>
                                            <h6 className="text-muted text-right">{prettyPrice(invoiceAggregations.totalBetweenThirtyOneAndSixtyDays)}</h6>
                                            <h6 className="text-muted text-right">{prettyPrice(invoiceAggregations.totalBetweenSixtyOneAndNinetyDays)}</h6>
                                            <h6 className="text-muted text-right">{prettyPrice(invoiceAggregations.totalOverNinetyDays)}</h6>
                                        </div>
                                    </div>
                                ) : (<>
                                    <div>
                                        <h5 className="text-muted"><a className="interval" onClick={() => setSelectedDateRangeCategory(intervalDateRanges.lessThanThirty)}>&lt;= 30 Days</a></h5>
                                        <h3 className="text-muted">{prettyPrice(invoiceAggregations.totalUnderThirtyDays)}</h3>
                                    </div>
                                    <div>
                                        <h5 className="text-muted"><a className="interval" onClick={() => setSelectedDateRangeCategory(intervalDateRanges.thirtyOneToSixty)}>31-60 Days</a></h5>
                                        <h3 className="text-muted">{prettyPrice(invoiceAggregations.totalBetweenThirtyOneAndSixtyDays)}</h3>
                                    </div>
                                    <div>
                                        <h5 className="text-muted"><a className="interval" onClick={() => setSelectedDateRangeCategory(intervalDateRanges.sixtyOneToNinety)}>61-90 Days</a></h5>
                                        <h3 className="text-muted">{prettyPrice(invoiceAggregations.totalBetweenSixtyOneAndNinetyDays)}</h3>
                                    </div>
                                    <div>
                                        <h5 className="text-muted"><a className="interval" onClick={() => setSelectedDateRangeCategory(intervalDateRanges.overNinety)}>Over 90 Days</a></h5>
                                        <h3 className="text-muted">{prettyPrice(invoiceAggregations.totalOverNinetyDays)}</h3>
                                    </div>
                                    </>)
                                }
                                {
                                    !!invoiceDetail ? (
                                        <div className="d-flex flex-column">
                                            <Button className="mb-1 p-1" style={{width: 120}} variant="outline-primary" disabled={bulkPaymentsLoading} onClick={onBulkPaymentsClick}>{ bulkPaymentsLoading ? (<Spinner animation="border" style={{height: 16, width: 16}}/>) : "Pay Invoices"}</Button>
                                            <Button className="p-1" style={{width: 120}} variant="outline-primary">Print Statement</Button>
                                        </div>
                                    ) : (
                                        <span>
                                            <Button className="mx-2 p-3" variant="outline-primary" onClick={onBulkPaymentsClick}>Pay Invoices</Button>
                                            <Button className="p-3" variant="outline-primary" onClick={handleDownloadStatement} disabled={statementLoading}>
                                                {statementLoading ? (
                                                    <Spinner animation="border" className="icon-spinner" />
                                                ) : 'Print Statement'}
                                            </Button>
                                        </span>
                                    )
                                }

                                </div>
                            )}

                        </Card.Body>
                    </Card>
                    <div style={{width: "100%", height: "100%", overflow: "auto"}}>
                        <DataGridPro
                            columns={columns}
                            rows={rows}
                            paginationMode="server"
                            pagination
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                            rowCount={isRowCountLoading ? 0 : rowCount}
                            onPageChange={(page => setPageNumber(page))}
                            page={pageNumber}
                            pageSize={pageSize}
                            disableColumnFilter={true}
                            disableColumnSelector={true}
                            disableMultipleColumnsSorting={true}
                            rowsPerPageOptions={[25, 50, 100]}
                            onPageSizeChange={(size) => setPageSize(size)}
                            onRowClick={row => {setInvoiceDetail(row.row)}}
                            selectionModel={selectionModel}
                            onSelectionModelChange={( selectionModel) =>
                               setSelectionModel(selectionModel)
                            }
                            sx={{
                                backgroundColor: "white"
                            }}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box"
                    },
                }}
                variant="persistent"
                anchor="right"
                open={!!invoiceDetail}
            >
                <h2 className="p-4 mb-2 pb-0 d-flex justify-content-between">
                    <span>
                        Invoice&nbsp;
                        { downloadLoading ? (<Spinner animation="border" className="icon-spinner" />) : (<IconButton size="small" onClick={() => handleDownloadInvoice(invoiceDetail.invoice_id)}><FileDownloadOutlinedIcon fontSize="small" /></IconButton>)}
                    </span>
                    <i
                        onClick={() => {
                            setSelectionModel([]);
                            setInvoiceDetail(null);
                        }}
                        className="bi bi-x"
                        role="img"
                        aria-label="Navigation Menu"
                    />


                </h2>
                <div className="p-4 pt-1 overflow-auto">
                    {!!invoiceDetail && (
                        <InvoiceDetail passedInvoiceId={invoiceDetail.invoice_id} onTicketClick={handleTicketClick} />
                    )}
                </div>
            </Drawer>
            <Modal
                show={!!ticketDetail}
                onHide={() => {setTicketDetail(null)}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ticket #{ticketDetail?.ticket_number}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight: 600}}>
                    {ticketDetail && <TicketDetail passedTicket={ticketDetail} />}

                </Modal.Body>
            </Modal>
            {renderBulkPayments()}
        </>
    )

}

export default InvoiceHistoryDesktop;

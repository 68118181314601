import React, { useEffect, useState } from 'react';
import { Form, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';

import './BulkPaymentsModal.scss';

import PaymentFormButton from '../payment-form-button/PaymentFormButton';

import { prettyPrice } from '../../utils/numberFormatter';
import { formattedDate } from '../../utils/dateFormatter';

export default function BulkPaymentsModal({
    pendingInvoices,
    show,
    onHide,
    ...props
}) {

    const [allInvoicesSelected, setAllInvoicesSelected] = useState(true);
    const [selectedInvoices, setSelectedInvoices] = useState({});

    useEffect(() => {
        if (!show) {
            setAllInvoicesSelected(true);
            setSelectedInvoices({});
        } else {
            toggleSelectAllInvoices(true);
        }
    }, [show]);

    //
    // Private Helpers
    //

    function toggleSelectAllInvoices(value) {
        const selectedInvoicesMap = {};
        const newSelectValue = (typeof value === "boolean") ? value : !allInvoicesSelected;

        pendingInvoices.forEach((invoice) => {
            selectedInvoicesMap[invoice.invoice_id] = newSelectValue;
        })

        setAllInvoicesSelected(newSelectValue);
        setSelectedInvoices(selectedInvoicesMap);
    }

    function toggleSelectInvoice(invoice) {
        const currentSelectValue = selectedInvoices[invoice.invoice_id];

        setAllInvoicesSelected(false);
        setSelectedInvoices({
            ...selectedInvoices,
            [invoice.invoice_id]: !currentSelectValue,
        });
    }

    function isInvoiceSelected(invoice) {
        return selectedInvoices[invoice.invoice_id];
    }

    function getSelectedInvoices() {
        return pendingInvoices.filter(isInvoiceSelected);
    }

    //
    // Render Helpers
    //

    function renderOverduePaymentIcon(invisible) {
        return (
            <OverlayTrigger
                key={"top"}
                placement={"top"}
                overlay={<Tooltip>Past Due</Tooltip>}
            >
                <div className={(invisible ? "invisible" : "") + " d-inline-block mx-2"}>
                    <i className="bi bi-exclamation-triangle-fill text-warning" />
                </div>
            </OverlayTrigger>
        )
    }

    function renderBulkPaymentsTable() {
        return (
            <Row className="flush-row">
                <Table id="Bulk-Payments-Table" responsive striped className="mb-2">
                    <thead className="table-light">
                        <tr>
                            <th>
                                <Form>
                                    <Form.Check type="checkbox">
                                        <Form.Check.Input
                                            type="checkbox"
                                            checked={allInvoicesSelected}
                                            onChange={toggleSelectAllInvoices}
                                        />
                                    </Form.Check>
                                </Form>
                            </th>
                            <th>Invoice ID</th>
                            <th>Due Date</th>
                            <th>Amount Due</th>
                        </tr>
                    </thead>
                    <tbody className="text-nowrap">
                        {(pendingInvoices || []).map((invoice) => {
                            return (
                                <tr key={invoice.invoice_id}>
                                    <td>
                                        <Form>
                                            <Form.Check type="checkbox">
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    checked={isInvoiceSelected(invoice)}
                                                    onChange={() => toggleSelectInvoice(invoice)}
                                                />
                                            </Form.Check>
                                        </Form>
                                    </td>
                                    <td>{invoice.invoice_number}</td>
                                    <td>
                                        {renderOverduePaymentIcon(!invoice.isOverdue)}
                                        {formattedDate(invoice.due_date)}
                                    </td>
                                    <td>{prettyPrice(invoice.total_amount_due)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Row>
        )
    }

    return (
        <Modal
            id="Bulk-Payments-Modal"
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Pay Invoices</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {renderBulkPaymentsTable()}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <PaymentFormButton
                    className="flex-grow-1"
                    invoices={getSelectedInvoices()}
                    showTermsAndConditions={true}
                    buttonSize={"lg"}
                    onInitiatePayment={onHide}
                />
            </Modal.Footer>
        </Modal>
    );
};

import axios from "axios";
import { BASE_API_URL } from "./baseApi";
import { refreshInterceptor } from "./authenticationApi";

const invoiceAxios = axios.create({
  baseURL: `${BASE_API_URL}/api/invoice`,
  withCredentials: true, // Required to send cookies
});

const invoicesAxios = axios.create({
  baseURL: `${BASE_API_URL}/api/invoices`,
  withCredentials: true, // Required to send cookies
});

const desktopInvoicesAxios = axios.create({
  baseURL: `${BASE_API_URL}/api/desktop/invoices`,
  withCredentials: true, // Required to send cookies
});

const statementAxios = axios.create({
  baseURL: `${BASE_API_URL}/api/statement`,
  withCredentials: true, // Required to send cookies
});

invoiceAxios.interceptors.response.use(
  (res) => res,
  (error) => refreshInterceptor(error)
);
invoicesAxios.interceptors.response.use(
  (res) => res,
  (error) => refreshInterceptor(error)
);
desktopInvoicesAxios.interceptors.response.use(
  (res) => res,
  (error) => refreshInterceptor(error)
);
statementAxios.interceptors.response.use(
  (res) => res,
  (error) => refreshInterceptor(error)
);

/**
 * Fetches a list of invoices for the current user.
 *
 * @param status        The status of tickets to fetch (pending or paid).
 * @param startDate     The start date used to filter what invoices should be returned.
 * @param endDate       The end date used to filter what invoices should be returned.
 * @param searchTerm    [Optional] A search term to filter out invoices.
 * @param plantId       [Optional] A specific plantId used to filter out invoices.
 * @param offset        Pagination offset.
 * @param limit         Pagination limit.
 * @param token         The JWT access token.
 *
 * @returns A list of invoices.
 */
export async function getInvoices({
  status,
  startDate,
  endDate,
  searchTerm,
  plantId,
  limit,
  offset,
  token,
}) {
  return invoicesAxios
    .get(`/${status}/`, {
      params: {
        search: searchTerm ? searchTerm.trim() : undefined,
        plantId,
        startDate,
        endDate,
        limit,
        offset,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => Promise.reject(err)
    );
}

/**
 * Fetches a paginated list of invoices for the current user, including both paid and due invoices.
 *
 * @param startDate     The start date used to filter what invoices should be returned.
 * @param endDate       The end date used to filter what invoices should be returned.
 * @param status        [Optional] Invoice Status (PAID or DUE)
 * @param searchTerm    [Optional] A search term to filter out invoices.
 * @param plants        [Optional] A semicolon-separated list of plantIds used to filter out invoices.
 * @param customers     [Optional] A semicolon-separated list of customers (plantId-customerNumber) used to filter out invoices
 * @param orders        [Optional] A semicolon-separated list of order ids used to filter out invoices
 * @param orderBy       [Optional] A column to sort by
 * @param sortOrder     [Optional] ASC or DESC
 * @param offset        Pagination offset.
 * @param limit         Pagination limit.
 * @param token         The JWT access token.
 * @param cancelToken   allows the api call to be cancelled
 *
 * @returns A list of invoices.
 */
export async function getDesktopInvoices({
  startDate,
  endDate,
  status,
  searchTerm,
  plants,
  customers,
  orders,
  orderBy,
  sortOrder,
  limit,
  offset,
  token,
  cancelToken,
}) {
  return desktopInvoicesAxios
    .get("/", {
      cancelToken,
      params: {
        search: searchTerm ? searchTerm.trim() : undefined,
        status,
        plants,
        customers,
        orders,
        startDate,
        endDate,
        orderBy,
        sortOrder,
        limit,
        offset,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => Promise.reject(err)
    )
    .catch(() => {}); // stops "uncaught in promise Cancel" error
}

/**
 * Fetches Filter Options for Desktop Invoice table
 *
 * @param token         The JWT access token.
 * @param cancelToken   allows the api call to be cancelled
 * @returns an object with a list of plant_ids and a list of customers (id and name)
 */
export async function getDesktopInvoiceFilters({ token, cancelToken }) {
  return desktopInvoicesAxios
    .get("/filters/", {
      cancelToken,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(
      (res) => res.data,
      (err) => Promise.reject(err)
    )
    .catch(() => {});
}

/**
 * Returns the total count of invoices with the given filters.
 *
 * @param startDate     The start date used to filter what invoices should be returned.
 * @param endDate       The end date used to filter what invoices should be returned.
 * @param searchTerm    [Optional] A search term to filter out invoices.
 * @param status        [Optional] Invoice Status (PAID or DUE)
 * @param plants        [Optional] A semicolon-separated list of plantIds used to filter out invoices.
 * @param customers     [Optional] A semicolon-separated list of customers (plantId-customerNumber) used to filter out invoices
 * @param orders        [Optional] A semicolon-separated list of order ids used to filter out invoices
 * @param invoice_numbers [Optional] A semicolon-separated list of invoices (plantId-invoiceId) used to filter out invoices
 * @param token         The JWT access token.
 * @param cancelToken   allows the api call to be cancelled
 *
 * @returns An integer count of invoices.
 */
export async function getInvoiceRowCount({
  startDate,
  endDate,
  searchTerm,
  status,
  plants,
  customers,
  orders,
  invoice_numbers,
  token,
  cancelToken,
}) {
  return desktopInvoicesAxios
    .get("/count/", {
      cancelToken,
      params: {
        search: searchTerm ? searchTerm.trim() : undefined,
        status,
        plants,
        customers,
        orders,
        invoice_numbers,
        startDate,
        endDate,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => Promise.reject(err)
    )
    .catch(() => {});
}

/**
 * Fetches details for a single invoice.
 *
 * @param invoiceId     The ID of the target invoice.
 * @param token         The JWT access token.
 *
 * @returns An invoice.
 */
export async function getInvoice({ invoiceId, token }) {
  return invoiceAxios
    .get(`/${invoiceId}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => Promise.reject(err)
    );
}

/**
 * Fetches a paginated list of tickets associated with an invoice.
 *
 * @param offset        Pagination offset.
 * @param limit         Pagination limit.
 * @param token         The JWT access token.
 *
 * @returns A list of tickets.
 */
export async function getInvoiceTickets({ invoiceId, offset, limit, token }) {
  return invoiceAxios
    .get(`/${invoiceId}/tickets/`, {
      params: {
        limit,
        offset,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(
      (res) => {
        return res.data;
      },
      (err) => Promise.reject(err)
    );
}

/**
 * Fetches a token to launch the Accept Hosted payment form.
 *
 * @param invoices      A list of invoices being paid.
 * @param token         The JWT access token.
 *
 * @returns A newly generated Authorize.NET form token.
 */
export async function getFormToken({ invoices, token, useACH = false }) {
  const invoiceIds = invoices.map((invoice) => invoice.invoice_id);

  return invoiceAxios
    .post(
      `/payments/form-token/`,
      {
        invoiceIds,
        useACH,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(
      (res) => {
        const token = res.data;
        if (!token) {
          throw Error("Invalid Authorize.NET form token.");
        } else {
          return token;
        }
      },
      (err) => Promise.reject(err)
    );
}

export async function downloadInvoice({ invoiceId, token }) {
  return invoiceAxios
    .get(`/${invoiceId}/download/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    })
    .then(
      (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const filename = `msczone-invoice-${invoiceId}.pdf`;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      },
      (err) => Promise.reject(err)
    );
}

export async function downloadStatement({ token }) {
  return statementAxios
    .get(`/download/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    })
    .then(
      (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        const today = new Date().toISOString().split("T");
        const filename = `msczone-statement-${today[0]}.pdf`;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      },
      (err) => Promise.reject(err)
    );
}

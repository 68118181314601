export const PLANTS = [
    { id: "1501", name: "AQUADALE QUARRY" },
    { id: "2101", name: "BV HEDRICK GRAVEL & SAND" },
    { id: "3107", name: "GREEN RIVER QUARRY" },
    { id: "1201", name: "GROVE STONE QUARRY" },
    { id: "1401", name: "LAKE NORMAN QUARRY" },
    { id: "3103", name: "NORMAN SAND COMPANY" },
    { id: "1202", name: "NORTH BUNCOMBE QUARRY" },
    { id: "3102", name: "PIEDMONT SAND COMPANY" },
    { id: "3106", name: "SOUTH MCDOWELL" }
]

export function getPlantById(plantId, plantsList = null) {
    if (plantsList && plantsList != []) {
        console.log(plantsList)
        return plantsList.find((plant) => plant.plantId === plantId) || "";
    }
    return PLANTS.find((plant) => plant.id === plantId) || "";
}

export function getPlantName(plantId, plantsList = null) {
    const plant = getPlantById(plantId, plantsList);
    return plant.plantName || plant.name;
}
import axios from "axios";
import { BASE_API_URL } from "./baseApi";
import { refreshInterceptor } from "./authenticationApi";

const plantsAxios = axios.create({
  baseURL: `${BASE_API_URL}/api/plants`,
  withCredentials: true, // Required to send cookies
});

plantsAxios.interceptors.response.use(
  (res) => res,
  (error) => refreshInterceptor(error)
);

export async function getPlants() {
  return plantsAxios.get("/").then(
    (res) => {
      return res.data;
    },
    (err) => Promise.reject(err)
  );
}

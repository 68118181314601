import React from 'react';
import { Badge, Button, Form, Row, InputGroup } from 'react-bootstrap';

import './SearchFiltersInput.scss';

export default function SearchFiltersInput({
    searchTerm,
    onSearchSubmit,
    onSetSearchTerm,
    onShowFilters,
    onResetFilters,
    hasFilters,
    inputLabel,
    inputPlaceholder,
    ...props
}) {
    
    function renderSearchBar() {
        return (
            <Row>
                <Form onSubmit={onSearchSubmit}>
                    <Form.Group id="Search-Filters-Input" className="px-3 pb-1">
                        <Form.Label className="d-flex justify-content-between">
                            <span>{inputLabel}</span>
                            {
                                hasFilters() &&
                                <Button variant="link" onClick={onResetFilters}>Clear Filters</Button>
                            }
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder={inputPlaceholder}
                                defaultValue={searchTerm}
                                onChange={e => onSetSearchTerm(e.target.value)}
                            />
                            <Button
                                variant={hasFilters() ? "outline-primary" : "outline-secondary"}
                                className="filter-button"
                                onClick={onShowFilters}
                            >
                                <i className="bi bi-sliders"></i>
                                {
                                    hasFilters() &&
                                    <Badge pill bg="primary">&nbsp;</Badge>
                                }
                            </Button>
                            <Button type="submit">
                                <i className="bi bi-search"></i>
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Row>
        );
    }

    return (
        <>
            {renderSearchBar()}
        </>
    );
};

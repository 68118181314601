import { 
    format,
    set,
    sub, 
    getYear, 
    startOfDay, 
    startOfWeek, 
    startOfMonth, 
    startOfYear,
    endOfDay, 
    endOfMonth, 
    endOfWeek, 
    endOfYear 
} from 'date-fns';


export function formattedDate(datetime) {
    try {
        return format(new Date(datetime), 'MM/dd/yyyy');
    } catch {
        console.error(`Could not parse datetime: ${datetime}`);
        return '';
    }
}

export function formattedTime(datetime) {
    try {
        return format(new Date(datetime), 'h:mm aaa');
    } catch {
        console.error(`Could not parse datetime: ${datetime}`);
        return '';
    }
}

function today() { return new Date(); }

export const intervalDateRanges = {
    lessThanThirty: {
        name: '<= 30 Days',
        rangeType: 'interval',
        startDate: sub(startOfDay(today()), { days: 30 }),
        endDate: endOfDay(today())
    },
    thirtyOneToSixty: {
        name: '31-60 Days',
        rangeType: 'interval',
        startDate: sub(startOfDay(today()), { days: 60 }),
        endDate: sub(endOfDay(today()), { days: 31 })
    },
    sixtyOneToNinety: {
        name: '61-90 Days',
        rangeType: 'interval',
        startDate: sub(startOfDay(today()), { days: 90 }),
        endDate: sub(endOfDay(today()), { days: 61 })
    },
    overNinety: {
        name: 'Over 90 Days',
        rangeType: 'interval',
        startDate: new Date(2015, 1, 1),
        endDate: sub(endOfDay(today()), { days: 91 })
    }
};

export function getFilterDateRanges() {
    const todayRange = [{
        name: 'Today',
        rangeType: 'day',
        startDate: startOfDay(today()),
        endDate: endOfDay(today())
    }];

    const dueIntervalRanges = Object.values(intervalDateRanges);

    // First calculate custom date ranges of this current week and the past week.
    const weekRanges = [
        {
            name: 'This Week',
            rangeType: 'week',
            startDate: startOfWeek(today()),
            endDate: endOfWeek(today())
        },
        {   
            name: 'Last Week',
            rangeType: 'week',
            startDate: sub(startOfWeek(today()), { weeks: 1 }),
            endDate: sub(endOfWeek(today()), { weeks: 1 }),
        }
    ];

    // Calculate monthly date ranges for the past six months.
    const monthRanges = [];
    for (let i = 0; i < 6; i++) {
        const startDate = sub(startOfMonth(today()), { months: i });
        const endDate = endOfMonth(startDate);

        monthRanges.push({
            name: format(startDate, 'MMMM yyyy'),
            rangeType: 'month',
            startDate,
            endDate,
        });
    }

    // Calculate yearly date ranges up to 3 years ago.
    const yearRanges = [];
    const currentYear = getYear(today());
    for (let i = currentYear; i >= (currentYear-3); i--) {
        const startDate = set(startOfYear(today()), { year: i });
        const endDate = endOfYear(startDate);

        yearRanges.push({
            name: format(startDate, 'yyyy'),
            rangeType: 'year',
            startDate,
            endDate,
        });
    }

    // Finally, format all ranges. 
    const allRanges = todayRange.concat(dueIntervalRanges).concat(weekRanges).concat(monthRanges).concat(yearRanges);
    allRanges.map((range) => {
        range.startDate = startOfDay(range.startDate);
        range.endDate = endOfDay(range.endDate);
    });
    
    return allRanges;
}

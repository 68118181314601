import React, { useState } from "react";

import PaymentForm from "../components/payment-form/PaymentForm";
import ACHDialogModal from "../components/ach-dialog-modal/ACHDialogModal";

const initState = {
  show: false,
  invoices: [],
  newProcessingInvoices: [],
  useACH: false,
  showACHDialog: false,
  launchPaymentForm: (invoices) => {},
  closePaymentForm: (newProcessingInvoices) => {},
  openACHDialog: () => {},
  closeACHDialog: () => {},
};

export const PaymentContext = React.createContext(initState);

export const PaymentProvider = (props) => {
  const launchPaymentForm = (invoices, useACH = false) => {
    setState({
      ...state,
      show: true,
      useACH,
      invoices,
      newProcessingInvoices: [],
    });
  };

  const closePaymentForm = (newProcessingInvoices) => {
    setState({
      ...state,
      show: false,
      invoices: [],
      useACH: false,
      newProcessingInvoices: newProcessingInvoices,
    });
  };

  const openACHDialog = (invoices) => {
    setState({
      ...state,
      invoices,
      showACHDialog: true,
    });
  };

  const closeACHDialog = () => {
    setState({
      ...state,
      showACHDialog: false,
    });
  };

  const [state, setState] = useState({
    ...initState,
    launchPaymentForm,
    closePaymentForm,
    openACHDialog,
    closeACHDialog,
  });

  return (
    <PaymentContext.Provider value={state}>
      {props.children}
      <PaymentForm />
      <ACHDialogModal
        show={state.showACHDialog}
        onHide={closeACHDialog}
        setACHChoice={(shouldUseACH) => {
          launchPaymentForm(state.invoices, shouldUseACH);
        }}
      ></ACHDialogModal>
    </PaymentContext.Provider>
  );
};

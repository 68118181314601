import axios from 'axios';
import { BASE_API_URL } from './baseApi';
import {refreshInterceptor} from "./authenticationApi";

const paymentsAxios = axios.create({
    baseURL: `${BASE_API_URL}/api/pending-payments`,
    withCredentials: true // Required to send cookies
});

paymentsAxios.interceptors.response.use((res) => res, error => refreshInterceptor(error));

/**
 * Creates a processing payment record for a list of invoices.
 * 
 * @param invoices      The invoices to include in the payment group.
 * @param transactionId The transactionId returned from Authorize.
 * @param token         The JWT access token.
 * 
 * @returns 
 */
export async function createProcessingPayment({
    invoices,
    transactionId,
    token,
}) {
    const paymentInvoices = invoices.map((invoice) => {
        return {
            invoiceId: invoice.invoice_id,
            customerId: invoice.customer_id,
        }
    });

    return paymentsAxios.post('/', {
        invoices: paymentInvoices,
        transactionId,
    }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(
        (res) => { return res.data; }
    );
}

/**
 * Fetches all processing payments for the current client.
 * 
 * @param token         The JWT access token.
 * 
 * @returns A list of processing payments.
 */
export async function getProcessingPayments({ token }) {
    return paymentsAxios.get('/', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(
        (res) => { return res.data; }
    );
}


/**
 * Expires a processing payment.
 * 
 * @param payment       The processing payment to expire.
 * @param token         The JWT access token.
 * 
 * @returns 
 */
 export async function expireProcessingPayments({ payment, token }) {
    return paymentsAxios.delete(`/${payment.id}/`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(
        (res) => { return res.data; }
    );
}

/**
 * Expires processing payments associated with a given invoice.
 * 
 * @param invoice       An invoice id. All associated payments will be expired.
 * @param token         The JWT access token.
 * 
 * @returns 
 */
 export async function expireProcessingPaymentsByInvoice({ invoice, token }) {
    return paymentsAxios.delete(`/invoice/${invoice.invoice_id}/`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(
        (res) => { return res.data; }
    );
}

import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Navigation from '../../components/navigation/Navigation';

import './DetailPage.scss';

export default function DetailPage({ breadcrumb, ...props }) {

    function renderPrintBanner() {
        return (
            <div className="on-print">
                <div className="d-flex justify-content-center mb-5">
                    <img id="print-nav-logo-full" src="/msc-logo-full.png" />
                </div>
            </div>
        )
    }

    return (
        <>
            <Navigation />
            <Container id="Detail">
                <div id="Detail-Nav" className="py-3 no-print">
                    <Link to={breadcrumb.route}>
                        <i className="bi bi-chevron-left" />
                        &nbsp;Back to {breadcrumb.title}
                    </Link>
                </div>
                {renderPrintBanner()}
                <div id="Detail-Body">
                    {props.children}
                </div>
            </Container>
        </>
    );
};

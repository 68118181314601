import React, { useEffect, useState } from 'react';

import './InvoiceStatus.scss';
import '../info-icon/InfoIcon';
import InfoIcon from '../info-icon/InfoIcon';
import { getFullName } from '../../../utils/userFormatter';

export default function InvoiceStatus({ invoice, hideInfo, ...props }) {

    const [state, setState] = useState({
        text: "text-secondary",
        tooltip: ""
    });

    useEffect(() => {
        if (invoice.isProcessing) {
            const userName = getFullName(invoice.processingPayment?.user);
            const iconTextPrefix = userName ? 
                `${userName} submitted a payment for this invoice.` : 
                'A payment has been submitted for this invoice.';
            setState({
                statusText: "Payment Pending",
                textClass: "text-primary",
                iconText: `${iconTextPrefix} It should be processed in 2 business days.
                    If you have questions about this payment, please contact the MSC team for help.`,
            });
        } else if (invoice.isOverdue) {
            setState({
                statusText: "Past Due",
                textClass: "text-danger",
            });
        } else if (invoice.isPending) {
            setState({
                statusText: "Due",
                textClass: "text-warning",
            });
        } else {
            setState({
                statusText: "Paid",
                textClass: "text-success",
            });
        }
    }, [invoice]);

    return (
        <strong className={state.textClass}>
            {state.statusText}
            {state.iconText && !hideInfo &&
                <InfoIcon className={state.textClass} text={state.iconText} />
            }
        </strong>
    );
}
import React, { useContext, useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    InputGroup
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import './Login.scss';

import { AuthContext } from '../../providers/authProvider';
import {sendResetPasswordEmail} from '../../apis/authenticationApi';
import { generateMailToLink, generateTelLink } from '../../utils/contactLinkFormatter';

import Navigation from '../navigation/Navigation';


export default function Login(props) {
    const { token, login } = useContext(AuthContext);

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const [resetPasswordEmail, setResetPasswordEmail] = useState('');
    const [resetPasswordEmailErrorMessage, setResetPasswordEmailErrorMessage] = useState('');

    const [errorMessage, setErrorMessage] = useState();

    const [forgotModal, setForgotModal] = useState({
        show: false,
        topic: undefined,
    });

    const handleModalClose = () => {
        setResetPasswordEmailErrorMessage('');
        setForgotModal({ show: false, topic: undefined });
    }
    const handleModalShow = (topic) => setForgotModal({ show: true, topic: topic });

    const handleLogin = (e) => {
        e.preventDefault();

        if (!username || !password) {
            setErrorMessage('Username and password are required.')
        } else {
            login({
                username,
                password
            }).catch((err) => {
                setErrorMessage('Invalid username or password. Please update and try again.');
            });
        }
    }

    useEffect(() => {
        if (!!token) {
            // Once the user has successfully logged in, 
            // redirect them to the home page
            props.history.push('/');
        }
    }, [props, token]);

    const handleResetPasswordSubmit = () => {
        if(!resetPasswordEmail) {
            setResetPasswordEmailErrorMessage("Email address is required.")
            return;
        }

        sendResetPasswordEmail({email: resetPasswordEmail}).then(res => {
            console.log(res);
            handleModalClose();
        }).catch(err => {
            if(err?.response?.data?.message) {
                setResetPasswordEmailErrorMessage(err.response.data.message);
            } else {
                setResetPasswordEmailErrorMessage("We weren't able to send you an email, please try again later.")
            }
        })


    }




    function renderForgotModal() {
        const { show, topic } = forgotModal;

        const phone = "704-633-4241";
        const email = "mscinvoices@hedrickind.com";

        // Generate initital email template for mailto links
        let subject, body;
        switch (topic) {
            case 'email':
                subject = 'Forgotten MSC Zone Email';
                body = `Hello, 
I've forgotten the email address for my MSC Zone account. 

Full Name: 
                `;
                break;
            case 'password':
                subject = 'Reset MSC Zone Password';
                body = `Hello, 
I am unable to access my MSC Zone account, and would like to reset my password. 

Email Address: 
Full Name: 
                `;
                break;
        }

        const mailtoLink = generateMailToLink(email, subject, body);
        const telLink = generateTelLink(phone);

        return (
            <Modal
                enforceFocus={false}
                id="Login-Forgot-Modal"
                show={show}
                onHide={handleModalClose}
                centered
            >
                {topic === 'email' ? (
                    <>
                        <Modal.Body>
                            <p>{`To reset your ${topic} please reach out to Hedrick support:`}</p>
                            <p>Phone: <a href={telLink}>{phone}</a></p>
                            <p>Email: <a href={mailtoLink}>{email}</a></p>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center">
                            <Button variant="primary" size="lg" onClick={handleModalClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                ) : (
                        <>
                            <Modal.Body>
                                <h2 className="text-center">Forgot your password?</h2>
                                <p className="text-center">We'll send you a link to reset it.</p>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        className="p-3"
                                        placeholder="Please enter your email"
                                        value={resetPasswordEmail}
                                        onChange={e => setResetPasswordEmail(e.target.value)}
                                        isInvalid={!!resetPasswordEmailErrorMessage}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {resetPasswordEmailErrorMessage}
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-center">
                                <Button variant="secondary" size="lg" onClick={handleModalClose}>
                                    Cancel
                                </Button>
                                <Button variant="primary" size="lg" onClick={handleResetPasswordSubmit}>Submit</Button>
                            </Modal.Footer>
                        </>
                )}
            </Modal>
        );
    }

    return (
        <div id="Login">
            <Navigation />
            <Container>
                <Row className="align-items-center justify-content-center vh-100">
                    <Col xs={12} md={6} lg={4} className="px-4">
                        <h1 className="mb-4">
                            Log in to the M.S.C. Zone
                        </h1>
                        {!!errorMessage &&
                            <Alert variant='danger'>{errorMessage}</Alert>
                        }
                        {renderForgotModal()}
                        <Form onSubmit={handleLogin}>
                            <Form.Group className="mb-3">
                                <Form.Label className="d-flex align-items-center justify-content-between">
                                    <span>Email address</span>
                                    <Button variant="link" onClick={() => handleModalShow('email')}>Forgot Email?</Button>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="john.smith@gmail.com"
                                    onChange={event => setUsername(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label className="d-flex align-items-center justify-content-between">
                                    <span>Password</span>
                                    <Button variant="link" onClick={() => handleModalShow('password')}>Forgot Password?</Button>
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="**************"
                                    onChange={event => setPassword(event.target.value)}
                                />
                            </Form.Group>
                            <div className="d-grid pt-2">
                                <Button variant="primary" type="submit" size="lg">Login</Button>
                            </div>
                        </Form>
                        <div className="text-center mt-4">
                            <span>Don't have an account?</span><br />
                            <NavLink to="/signup">Sign up here</NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

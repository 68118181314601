import { AuthProvider } from './providers/authProvider';
import { PaymentProvider } from './providers/paymentProvider';
import { PlantsProvider } from './providers/plantsProvider';
import { TicketFiltersProvider } from "./providers/ticketFiltersProvider";

export default function AppProviders(props) {
    return (
        <AuthProvider>
            <PaymentProvider>
                <TicketFiltersProvider>
                    <PlantsProvider>
                        {props.children}
                    </PlantsProvider>
                </TicketFiltersProvider>
            </PaymentProvider>
        </AuthProvider>
    );
}

import React, {useContext, useState} from "react";
import { NavLink } from 'react-router-dom';
import {AuthContext} from '../../providers/authProvider';

import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    InputGroup
} from 'react-bootstrap';

import { updatePassword } from '../../apis/authenticationApi';


const UpdatePassword = () => {
    const {token} = useContext(AuthContext);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};
        if(!oldPassword) {
            newErrors = {...newErrors, oldPassword: 'Current password is required'}
        }
        if(!newPassword) {
            newErrors = {...newErrors, newPassword: 'New password is required'}
        }
        if(!confirmPassword) {
            newErrors = {...newErrors, confirmPassword: 'Confirmation password is required'}
        }
        else if(confirmPassword !== newPassword) {
            newErrors = {...newErrors, confirmPassword: 'Passwords do not match'}
        }

        if(Object.values(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }


        updatePassword({
            token,
            old_password: oldPassword,
            new_password: newPassword
        }).then(res => {
            setErrors({});
            setSuccess(true);
        }).catch(err => {
            setErrors(err.response.data);
        });
    }

    if(success) {
        return (
            <div id="Login">
                <Container>
                    <Row className="align-items-center justify-content-center mt-5">
                        <Col xs={12} md={6} lg={4} className="px-4">
                            <h1 className="mb-4 text-center">
                                Password successfully reset!
                            </h1>
                            <NavLink className="text-center" to="/"><h4>Return to Home page</h4></NavLink>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    return (
        <div id="Login">
            <Container>
                <Row className="align-items-center justify-content-center mt-5">
                    <Col xs={12} md={6} lg={4} className="px-4">
                        <h1 className="mb-4">
                            Reset your M.S.C. Zone password
                        </h1>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-4">
                                <Form.Label className="d-flex align-items-center justify-content-between">
                                    <span>Current Password</span>
                                </Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="password"
                                        placeholder="**************"
                                        onChange={e => setOldPassword(e.target.value)}
                                        isInvalid={!!errors.oldPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.oldPassword}
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label className="d-flex align-items-center justify-content-between">
                                    <span>New Password</span>
                                </Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="password"
                                        placeholder="**************"
                                        onChange={e => setNewPassword(e.target.value)}
                                        isInvalid={!!errors.newPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.newPassword}
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label className="d-flex align-items-center justify-content-between">
                                    <span>Confirm New Password</span>
                                </Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="password"
                                        placeholder="**************"
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        isInvalid={!!errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </InputGroup>

                            </Form.Group>
                            <div className="d-grid pt-2">
                                <Button variant="primary" type="submit" size="lg">Submit</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default UpdatePassword;

import React, {useContext, useEffect, useState} from 'react';
import {getTicketRowCount} from "../../apis/ticketsApi";
import {formatArrayFilter, sortByProperty} from "../../utils/miscUtils";
import {AuthContext} from "../../providers/authProvider";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import {Dropdown, Table} from "react-bootstrap";
import {formatQuantity} from "../../utils/ticketFormatter";
import {formattedDate, getFilterDateRanges} from "../../utils/dateFormatter";
import SearchFiltersInput from "../search-filters-input/SearchFiltersInput";
import SearchFiltersModal from "../search-filters-modal/SearchFiltersModal";
import {PAGES} from "../search-filters-input/SearchFiltersDesktop";
import {TicketFiltersContext} from "../../providers/ticketFiltersProvider";

const TonnageSummaryMobile = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [tonnageLoaded, setTonnageLoaded] = useState(false);
    const [tonnageProducts, setTonnageProducts] = useState([]);
    const [dateRange, setDateRange] = useState();
    const {token} = useContext(AuthContext);

    const { filters, setFilters, searchTerm, setSearchTerm, resetFilters } = useContext(TicketFiltersContext);
    const [filterModal, setFilterModal] = useState({ show: false });

    const handleFiltersClose = () => setFilterModal({ show: false });
    const handleFiltersShow = () => setFilterModal({ show: true });


    useEffect(() => {
        let isMounted = true;
        if(!tonnageLoaded) {
            getTonnageSummary().then(res => {
                if(isMounted) {
                    setTonnageSummary(res);
                }
            });
        }


        return () => {
            isMounted = false;
        }
    }, [tonnageLoaded]);

    const getTonnageSummary = () => {
        setIsLoading(true);

        const { dateRange, plantId, product, order } = filters;

        return getTicketRowCount({
            startDate: dateRange?.startDate ? dateRange.startDate.toLocaleDateString() : undefined,
            endDate: dateRange?.endDate ? dateRange.endDate.toLocaleDateString() : undefined,
            orders: order || undefined,
            products: product || undefined,
            plants: plantId || undefined,
            searchTerm: searchTerm || undefined,
            token
        });
    }

    const setTonnageSummary = (res) => {
        setTonnageProducts(res.by_product.sort((a, b) => sortByProperty(a,b, 'tonnage', false)));
        setIsLoading(false);
        setTonnageLoaded(true);
    }

    const handleSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setTonnageLoaded(false);
    }

    const handleSetFilters = (newFilters) => {
        setFilters(newFilters);
        setTonnageLoaded(false);
        handleFiltersClose();
    }

    const handleResetFilters = () => {
        resetFilters();
        setSearchTerm(undefined);
        setTonnageLoaded(false);
        handleFiltersClose();
    }

    const hasFilters = () => {
        const { dateRange, plantId, order, product } = filters;

        return !!dateRange.startDate || !!plantId || !!order || !!product;
    }

    function renderSearchBar() {
        return (
            <SearchFiltersInput
                searchTerm={searchTerm}
                onSearchSubmit={handleSearch}
                onSetSearchTerm={setSearchTerm}
                onShowFilters={handleFiltersShow}
                onResetFilters={handleResetFilters}
                hasFilters={hasFilters}
                inputLabel=""
                inputPlaceholder="Search by ticket, po, order, or product"
            ></SearchFiltersInput>
        )
    }

    function renderFilterModal() {
        return (
            <SearchFiltersModal
                initFilters={filters}
                show={filterModal.show}
                onHide={handleFiltersClose}
                onApplyFilters={handleSetFilters}
                onClearFilters={handleResetFilters}
                title="Filter Tickets"
                page={PAGES.Tickets}
            ></SearchFiltersModal>
        );
    }

    function renderDateRangeFilter() {
        const dateRanges = getFilterDateRanges();

        return (
            <Dropdown className="mb-3 d-grid">
                <Dropdown.Toggle variant="outline-secondary" size="lg">
                    {dateRange?.name || "Select a Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        dateRanges.map((range) => {
                            return (
                                <Dropdown.Item onClick={() => setDateRange(range)} key={range.name}>
                                    {range.name}&nbsp;
                                    {(range.rangeType === 'week') &&
                                        <>
                                            ({formattedDate(range.startDate)} - {formattedDate(range.endDate)})
                                        </>
                                    }
                                </Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }


    return (
        <>
            <h2 className="mb-3 pb-0">
                Tonnage Summary
            </h2>
            {renderSearchBar()}
            <div className="mt-3">
                {
                    isLoading ? (
                        <LoadingSpinner typ="page" />
                    ) : (
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th className="text-right">Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tonnageProducts.map(product => (
                                    <tr key={product.product}>
                                        <td>{product.product}</td>
                                        <td className="text-right">{formatQuantity({
                                            qty: product.tonnage,
                                            unit: 'Tons'
                                        })}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                }

            </div>
            {renderFilterModal()}
        </>
    );
}


export default TonnageSummaryMobile;

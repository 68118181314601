import React, { useEffect, useState } from 'react';

import { createAndAuthorizeUser, getAuthorizedUser, getJwtToken, refreshJwtToken, removeJwtToken } from '../apis/authenticationApi';

const initState = {
    token: undefined,
    authorizedUser: undefined,
    isSuperuserSession: undefined,
    login: ({ username, password }) => Promise.resolve(),
    logout: () => Promise.resolve(),
    signup: () => Promise.resolve(),
    refreshToken: () => Promise.resolve(),
};

export const AuthContext = React.createContext(initState);

export const AuthProvider = (props) => {
    const handleJwtToken = (accessToken) => {
        // Decode the access token and determine if this is a superuser session.
        const [header = "", payload = "", signature = ""] = accessToken.split('.');
        let parsedPayload = {};
        try {
            parsedPayload = JSON.parse(window.atob(payload));
        } catch {
            console.error("Could not parse token payload!")
        }
        const isSuperuserSession = !!parsedPayload['target_user_id'];

        // Store access token in memory
        setState({ ...state, token: accessToken, isSuperuserSession });
        return accessToken;
    }

    const login = async ({ username, password }) => {
        return getJwtToken({ username, password }).then(handleJwtToken);
    }

    const signup = async ({
        firstName,
        lastName,
        emailAddress,
        password,
        invoiceNumber,
        customerNumber,
        plantId,
    }) => {
        return createAndAuthorizeUser({
            firstName,
            lastName,
            emailAddress,
            password,
            invoiceNumber,
            customerNumber,
            plantId,
        }).then(handleJwtToken);
    }

    const refreshToken = async () => {
        return refreshJwtToken().then(
            handleJwtToken,
            (err) => { return undefined; }
        );
    }

    const logout = async () => {
        return removeJwtToken().then(
            () => {
                // Clear the accessToken saved locally. 
                setState({ ...state, token: undefined });
            },
            (err) => {
                // TODO: Error handling
            }
        );
    }

    const [state, setState] = useState({
        ...initState,
        login,
        logout,
        signup,
        refreshToken,
    });

    useEffect(() => {
        // When our state token is set, attempt to fetch the authorized user details. 
        if (!!state.token && !state.authorizedUser) {
            getAuthorizedUser(state.token).then(
                (user) => {
                    setState({ ...state, authorizedUser: user })
                },
                (err) => {
                    // TODO: Error handling
                }
            );
        }
    }, [state.token, state.authorizedUser]);

    return (
        <AuthContext.Provider value={state}>
            {props.children}
        </AuthContext.Provider>
    )
}

import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import "./CustomerProfileForm.scss";
import { getPlantName } from "../../constants/plants";
import { PlantsContext } from "../../providers/plantsProvider";

export default function CustomerProfileForm({
  handleSubmit,
  btnText,
  ...props
}) {
  const { plants } = useContext(PlantsContext);
  const [formValidated, setFormValidated] = useState(false);

  const [moreInfoModal, setMoreInfoModal] = useState({
    show: false,
    topic: undefined,
  });

  const handleInfoClose = () =>
    setMoreInfoModal({ show: false, topic: undefined });
  const handleInfoShow = (topic) =>
    setMoreInfoModal({ show: true, topic: topic });

  // Invoice details form state
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [customerNumber, setCustomerNumber] = useState();
  const [plantId, setPlantId] = useState();

  const extendedHandleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setFormValidated(true);

    if (invoiceNumber && customerNumber && plantId) {
      handleSubmit({
        invoiceNumber,
        customerNumber,
        plantId,
      });
    }
  };

  function renderInfoModal() {
    const { show, topic } = moreInfoModal;
    let infoText, infoImg;

    switch (topic) {
      case "invoice-number":
        infoText = "Find the invoice number on your invoice as shown below.";
        infoImg = "/plant-and-invoice-zoom.gif";
        break;
      case "customer-number":
        infoText = "Find the customer number on your invoice as shown below.";
        infoImg = "/customer-number-zoom.gif";
        break;
      case "plant-id":
        infoText = "Find the plant name on your invoice as shown below.";
        infoImg = "/plant-and-invoice-zoom.gif";
        break;
    }

    return (
      <Modal
        id="Customer-Profile-Form-Modal"
        show={show}
        onHide={handleInfoClose}
        centered
      >
        <Modal.Body>
          <p>{infoText}</p>
          <img src={infoImg} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary" size="lg" onClick={handleInfoClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Form
        id="Customer-Profile-Form"
        onSubmit={extendedHandleSubmit}
        noValidate
        validated={formValidated}
      >
        <Form.Group className="mb-3">
          <Form.Label>
            <h5>Invoice Number</h5>
            <a
              href="#"
              className="form-help-label"
              onClick={() => handleInfoShow("invoice-number")}
            >
              Show me where to find my invoice number.
            </a>
          </Form.Label>
          <br />
          <Form.Control
            required
            type="number"
            onChange={(event) => setInvoiceNumber(event.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid invoice number.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <h5>Customer Number</h5>
            <a
              href="#"
              className="form-help-label"
              onClick={() => handleInfoShow("customer-number")}
            >
              Show me where to find my customer number.
            </a>
          </Form.Label>
          <Form.Control
            required
            type="number"
            onChange={(event) => setCustomerNumber(event.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid customer number.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <h5>Plant</h5>
            <a
              href="#"
              className="form-help-label"
              onClick={() => handleInfoShow("plant-id")}
            >
              Show me where to find the plant on my invoice.
            </a>
          </Form.Label>
          <Form.Select
            required
            onChange={(event) => setPlantId(event.target.value)}
          >
            <option value="" disabled={!!plantId}>
              Select from the following plants:
            </option>
            {plants.map((plant) => {
              return (
                <option value={plant.plantId} key={plant.plantId}>
                  {plant.plantName}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please select a plant.
          </Form.Control.Feedback>
        </Form.Group>

        <div className="d-grid mb-3">
          <Button variant="primary" size="lg" type="submit">
            {btnText}
          </Button>
        </div>
      </Form>
      {renderInfoModal()}
    </>
  );
}

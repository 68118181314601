import axios from 'axios';
import { BASE_API_URL } from './baseApi';
import {refreshInterceptor} from "./authenticationApi";

const ticketsAxios = axios.create({
    baseURL: `${BASE_API_URL}/api/tickets`,
    withCredentials: true // Required to send cookies
});

const desktopTicketsAxios = axios.create({
    baseURL: `${BASE_API_URL}/api/desktop/tickets`,
    withCredentials: true // Required to send cookies
})

const desktopTicketsCsvAxios = axios.create({
    baseURL: `${BASE_API_URL}/api/desktop/tickets`,
    withCredentials: true, // Required to send cookies
    responseType: 'blob'
})

ticketsAxios.interceptors.response.use((res) => res, error => refreshInterceptor(error));
desktopTicketsAxios.interceptors.response.use((res) => res, error => refreshInterceptor(error));
desktopTicketsCsvAxios.interceptors.response.use((res) => res, error => refreshInterceptor(error));



/**
 * Fetches a list of tickets for the current user.
 * 
 * @param startDate     The start date used to filter what tickets should be returned.
 * @param endDate       The end date used to filter what tickets should be returned.
 * @param searchTerm    [Optional] A search term to filter out tickets.
 * @param plantId       [Optional] A specific plantId used to filter out tickets.
 * @param plants        [Optional] A semicolon-separated list of plant_ids to filter out tickets.
 * @param customers     [Optional] A semicolon-separated list of customer_ids to filter out tickets.
 * @param orderBy       [Optional] A column to sort by, default is TicketDate and TicketTime DESC
 * @param orders        [Optional] A semicolon-separated list of order ids to filter out tickets
 * @param products      [Optional] A semicolon-separated list of products to filter out tickets
 * @param vehicles      [Optional] A semicolon-separated list of vehicle ids to filter out tickets
 * @param carriers      [Optional] A semicolon-separated list of carrier names to filter out tickets
 * @param sortOrder     [Optional] ASC or DESC
 * @param offset        Pagination offset.
 * @param limit         Pagination limit.
 * @param token         The JWT access token.
 * 
 * @returns A list of tickets.
 */
export async function getTickets({
    startDate,
    endDate,
    searchTerm,
    plantId,
    plants,
    customers,
    orders,
    products,
    vehicles,
    carriers,
    orderBy,
    sortOrder,
    offset,
    limit,
    token,
    cancelToken
}) {
    return ticketsAxios.post('/', {
        search: (searchTerm) ? searchTerm.trim() : undefined,
        plant_id: plantId,
        start_date: startDate,
        end_date: endDate,
        plants,
        customers,
        orders,
        products,
        vehicles,
        carriers,
        orderBy,
        sortOrder,
    }, {
        cancelToken,
        params: {
            limit,
            offset,
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(
        (res) => { return res.data; }
    );
}

/**
 * Fetches details for a single ticket.
 * 
 * @param ticketId      The ID of the target ticket. 
 * @param token         The JWT access token.
 * 
 * @returns A ticket.
 */
export async function getTicket({ ticketId, token }) {
    return ticketsAxios.get(`/${ticketId}/`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(
        (res) => { return res.data; }
    );
}


/**
 * Returns the total count of tickets with the given filters.
 *
 * @param startDate     The start date used to filter what tickets should be returned.
 * @param endDate       The end date used to filter what tickets should be returned.
 * @param searchTerm    [Optional] A search term to filter out tickets.
 * @param plants        [Optional] A semicolon-separated list of plant ids to filter out tickets
 * @param customers     [Optional] A semicolon-separated list of customer ids to filter out tickets
 * @param orders        [Optional] A semicolon-separated list of order ids to filter out tickets
 * @param products      [Optional] A semicolon-separated list of products to filter out tickets
 * @param vehicles      [Optional] A semicolon-separated list of vehicle ids to filter out tickets
 * @param carriers      [Optional] A semicolon-separated list of carrier names to filter out tickets
 * @param token         The JWT access token.
 *
 * @returns An integer count of tickets.
 */
export async function getTicketRowCount({
    startDate,
    endDate,
    searchTerm,
    plants,
    customers,
    orders,
    products,
    vehicles,
    carriers,
    token,
    cancelToken
}) {
    return desktopTicketsAxios.get('/count/', {
        cancelToken,
        params: {
            search: (searchTerm) ? searchTerm.trim() : undefined,
            plants,
            customers,
            orders,
            products,
            vehicles,
            carriers,
            start_date: startDate,
            end_date: endDate,
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(
        (res) => { return res.data; }
    );
}

/**
 * Fetches Filter Options for Desktop Invoice table
 *
 * @param token         The JWT access token.
 * @returns an object with all available filter options for the tickets table
 */
export async function getDesktopTicketFilters({token, cancelToken}) {
    return desktopTicketsAxios.get('/filters/', {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        cancelToken
    }).then(
        (res) => res.data
    );
}

export async function downloadTicketsCsv({
    startDate,
    endDate,
    searchTerm,
    plantId,
    plants,
    customers,
    orders,
    products,
    vehicles,
    carriers,
    orderBy,
    sortOrder,
    token,
}) {
    return desktopTicketsCsvAxios.get('/csv/',  {
        params: {
            search: (searchTerm) ? searchTerm.trim() : undefined,
            plant_id: plantId,
            start_date: startDate,
            end_date: endDate,
            plants,
            customers,
            orders,
            products,
            vehicles,
            carriers,
            orderBy,
            sortOrder,
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(
        (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            const today = (new Date()).toISOString().split('T');
            const filename =
                `msczone-export_${today[0]}.csv`
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
    );
}


export async function downloadTicket({ ticketId, token }) {
    return ticketsAxios.get(`/${ticketId}/download/`, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        responseType: 'blob'
    }).then(
        (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename =
                `msczone-ticket-${ticketId}.pdf`
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        }
    );
}

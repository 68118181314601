import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';

import './CollapseableCardSection.scss';

export default function CollapseableCardSection({ title, count, ...props }) {
    const isEmpty = count === 0;
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div className={(isOpen ? "open" : "closed") + (isEmpty ? " empty" : "") + " collapseable-card-section"}>
            <div
                className="collapseable-section-title text-center mx-3 mb-3"
                onClick={() => setIsOpen(!isOpen)}
            >
                <h5 className="d-flex justify-content-between">
                    <span>
                        {title}
                        {
                            typeof count === "number" &&
                            <Badge pill bg="secondary" className="mx-2">{count}</Badge>
                        }
                    </span>
                    <i className={isOpen ? "bi bi-chevron-down" : "bi bi-chevron-up"} />
                </h5>
            </div>
            <div className={isOpen ? "" : "d-none"}>
                {props.children}
            </div>
        </div>
    )
}
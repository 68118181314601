import React, { createContext, useState } from 'react';
import { PLANTS } from '../constants/plants';

const initialState = {
    plants: PLANTS,
    setPlants: (plantsList) => {}
}


export const PlantsContext = createContext(initialState);

export const PlantsProvider = (props) => {

    const setPlants = (plantsList) => {
        setState({...state, plants: plantsList})
    }

    const [state, setState] = useState({...initialState, setPlants});

    return (
        <PlantsContext.Provider value={state}>
            {props.children}
        </PlantsContext.Provider>
    )
}
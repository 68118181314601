import React, { useContext, useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    Row
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import './SignUp.scss';

import { verifyCustomerProfile } from '../../apis/authenticationApi';
import Navigation from '../../components/navigation/Navigation';
import CustomerProfileForm from '../../components/customer-profile-form/CustomerProfileForm';
import { AuthContext } from '../../providers/authProvider';

export default function SignUp(props) {
    // Top level page state (e.g. current form, validation, errors)
    const initSignUpState = {
        currentScreen: 'invoice',
        formValidated: false,
        errorMessage: undefined,
        errorMessageDetails: [],
    };
    const [signUpState, setSignUpState] = useState(initSignUpState);

    // Invoice details state
    const [invoiceDetails, setInvoiceDetails] = useState();

    // User details form state
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [emailAddress, setEmailAddress] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    const { signup, token } = useContext(AuthContext);

    useEffect(() => {
        if (!!token) {
            // Once the user has successfully logged in, 
            // redirect them to the home page
            props.history.push('/');
        }
    }, [props, token]);

    const setFormValidated = () => {
        setSignUpState({
            ...signUpState,
            formValidated: true,
        })
    }

    const setErrorMessage = (errorMessage, errorMessageDetails = []) => {
        setSignUpState({
            ...signUpState,
            errorMessage,
            errorMessageDetails,
        })
    }

    function handleInvoiceVerification(submittedInvoiceDetails) {
        const { invoiceNumber, customerNumber, plantId } = submittedInvoiceDetails;

        if (invoiceNumber && customerNumber && plantId) {
            verifyCustomerProfile({
                invoiceNumber,
                customerNumber,
                plantId,
            }).then((isValid) => {
                if (isValid) {
                    setInvoiceDetails(submittedInvoiceDetails);
                    setSignUpState({
                        ...initSignUpState,
                        currentScreen: 'user-details',
                    });
                } else {
                    setErrorMessage('Invalid invoice details. Please update and try again.');
                }
            }).catch((err) => {
                setErrorMessage('Encountered unexpected error. Please try again.');
            });
        }
    }

    const handleSignUp = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setFormValidated();

        if (!firstName || !lastName || !emailAddress || !password || !confirmPassword) {
            // no-op
        } else if (password !== confirmPassword) {
            // no-op
        } else {
            const { invoiceNumber, customerNumber, plantId } = invoiceDetails;

            signup({
                firstName,
                lastName,
                emailAddress,
                password,
                invoiceNumber,
                customerNumber,
                plantId,
            }).catch((err) => {
                const { status = 0, data = {} } = err.response;

                let errMsg = 'Encountered unexpected error. Please try again.';
                let errMsgDetails = [];

                console.error(`Error creating user: ${data}`)

                if (status >= 400 && status < 500) {
                    // Handle password error list.
                    if (typeof data == 'object' && data.password) {
                        errMsg = 'Invalid password';
                        errMsgDetails = data.password;
                    } else if (typeof data == 'string') {
                        errMsg = data;
                    }
                }

                setErrorMessage(errMsg, errMsgDetails);
            });
        }
    }

    function renderErrorMessage() {
        const { errorMessage, errorMessageDetails = [] } = signUpState;

        return (
            <>
                {!!errorMessage &&
                    <Alert variant='danger'>
                        {errorMessage}
                        {errorMessageDetails.length > 0 &&
                            <ul className="mb-0">
                                {errorMessageDetails.map((err, i) => <li key={i}>{err}</li>)}
                            </ul>
                        }
                    </Alert>
                }
            </>
        )
    }

    function renderInvoiceDetailScreen() {
        return (
            <>
                <div className="mb-3">
                    First, please find a previous MSC invoice.
                    We'll use the information on this invoice to confirm your account.
                </div>
                <CustomerProfileForm
                    handleSubmit={handleInvoiceVerification}
                    btnText="Next"
                />
            </>
        );
    }

    function renderUserDetailsScreen() {
        return (
            <>
                <Form
                    onSubmit={handleSignUp}
                    noValidate
                    validated={signUpState.formValidated}
                >
                    <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="e.g. John"
                            onChange={event => setFirstName(event.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a first name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="e.g. Smith"
                            onChange={event => setLastName(event.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a last name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            required
                            type="email"
                            placeholder="e.g. john.smith@gmail.com"
                            onChange={event => setEmailAddress(event.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid email.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            placeholder="**************"
                            onChange={event => setPassword(event.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid password.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            required
                            type="password"
                            placeholder="**************"
                            onChange={event => setConfirmPassword(event.target.value)}
                            isInvalid={(password !== confirmPassword)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {(password !== confirmPassword) ?
                                'Passwords do not match. Please update and try again.' :
                                'Please enter a valid password.'
                            }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="d-grid mb-3">
                        <Button size="lg" variant="primary" type="submit">Sign Up</Button>
                    </div>
                </Form>
            </>
        );
    }

    return (
        <div id="Sign-Up">
            <Navigation />
            <Container className="page-container">
                <Row className="align-items-center justify-content-center vh-100">
                    <Col xs={12} md={6} lg={4} className="px-4">
                        {signUpState.currentScreen === 'invoice' ?
                            <h1>Grab your last invoice to get started.</h1> :
                            <h1>Sign up to enter the M.S.C. Zone</h1>
                        }
                        {renderErrorMessage()}
                        {signUpState.currentScreen === 'invoice' ?
                            renderInvoiceDetailScreen() :
                            renderUserDetailsScreen()
                        }
                        <div className="text-center">
                            Already have an account? <br />
                            <NavLink to="/login">Log in here</NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

import { isBefore, sub, startOfDay, endOfDay, differenceInDays } from 'date-fns';

export function augmentInvoice(invoice, processingPaymentList) {
    const processingPayment = findProcessingPayment(invoice, processingPaymentList);
    invoice.processingPayment = processingPayment;

    if (isInvoicePending(invoice)) {
        invoice.isPending = true;

        if (!!processingPayment && !processingPayment.isExpired) {
            invoice.isProcessing = true;
        }

        if (isInvoiceOverdue(invoice)) {
            invoice.isOverdue = true;
        }
    }
    invoice.daysOld = daysOld(invoice);

    invoice.total_amount_due = (invoice.total_amount || 0) + (invoice.total_adjustments || 0);
}

export function isInvoiceProcessing(invoice, processingPaymentList) {
    let isProcessing = false;

    for (let i = 0; i < processingPaymentList.length; i++) {
        const payment = processingPaymentList[i];
        const paymentInvoiceId = `${payment.plant_id}-${payment.invoice_id}`;

        if (paymentInvoiceId === invoice.invoice_id) {
            isProcessing = true;
            break;
        }
    }

    return isProcessing;
}

export function findProcessingPayment(invoice, processingPaymentList) {
    for (let i = 0; i < processingPaymentList.length; i++) {
        const payment = processingPaymentList[i];
        const paymentInvoiceId = `${payment.plant_id}-${payment.invoice_id}`;

        if (paymentInvoiceId === invoice.invoice_id) {
            return payment;
        }
    }
}

export function isInvoicePending(invoice) {
    // TODO: Clean this up on the backend.
    const status = (invoice.status || invoice.invoice_status || '').toLowerCase();

    return status === 'due' || status === 'pending';
}

export function isInvoiceOverdue(invoice) {
    const overdueDate = sub(startOfDay(new Date()), { days: 30 });

    try {
        const invoiceDate = new Date(invoice.date);
        return isBefore(invoiceDate, overdueDate);
    } catch {
        console.error(`Could not parse datetime: ${invoice.date}`);
        return false;
    }
}

export function daysOld(invoice) {
    const today = startOfDay(new Date());
    try {
        const invoiceDate = new Date(invoice.date);
        return differenceInDays(today, invoiceDate);
    } catch {
        console.error(`Could not parse datetime: ${invoice.date}`);
        return 0;
    }
}

export function isProcessingPaymentExpired(payment) {
    try {
        // Pending payments should take about 2 business days to resolve. 
        // To roughly account for weekends and holidays, we'll expire 
        // processing payments after 5 days. 
        const expiredDate = sub(endOfDay(new Date()), { days: 5 });
        const paymentCreated = new Date(payment.created_timestamp);
        return isBefore(paymentCreated, expiredDate);
    } catch {
        console.error(`Could not parse payment created_timestamp: ${payment.created_timestamp}`);
        return false;
    }
}

export function formatOrderDescription(invoice) {
    const { order_id, order_description_1 } = invoice;
    const showDescription = !!order_description_1 && order_description_1 !== order_id;

    if (!order_id) {
        return '';
    } else if (showDescription) {
        return `${order_description_1} (#${order_id})`;
    } else {
        return order_id;
    }
}

export function formatCustomerNameAndId(invoice) {
    const { customer_name, customer_id } = invoice;

    if (!!customer_name && !!customer_id) {
        return `${customer_name} (#${customer_id})`;
    } else {
        return customer_name || customer_id || '';
    }
}

export function prettyNumber(num, decimalPlaces = 2) {
    let prettyNum = (num === undefined || num === null) ? 0 : num;
    try {
        prettyNum = prettyNum.toLocaleString('en-US', { minimumFractionDigits: decimalPlaces });
    } catch {
        prettyNum = `${prettyNum.toFixed(decimalPlaces)}`;
    }

    return prettyNum;
}

export function prettyPrice(num) {
    let prettyNum = (num === undefined || num === null) ? 0 : num;
    try {
        prettyNum = prettyNum.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    } catch {
        prettyNum = `$${prettyNum.toFixed(2)}`;
    }

    return prettyNum;
}
import { Button, Card } from 'react-bootstrap';

import { formattedDate, formattedTime } from '../../utils/dateFormatter';
import { formatCustomerNameAndId, formatOrderDescription, formatQuantity } from '../../utils/ticketFormatter';

export default function TicketHistoryCard({ ticket }) {
    return (
        <Card className="mb-3 mx-3">
            <Card.Body>
                <Card.Text className="d-flex justify-content-between">
                    <span><strong>Ticket #:</strong> {ticket.ticket_number}</span>
                    <span className="text-muted">{formattedDate(ticket.ticket_date)}</span>
                </Card.Text>
                <Card.Text className="d-flex justify-content-between">
                    <span>
                        <strong>Customer:</strong> {formatCustomerNameAndId(ticket) || 'N/A'}
                    </span>
                    <span className="text-muted">{formattedTime(ticket.ticket_time)}</span>
                </Card.Text>
                <Card.Text>
                    <strong>Order:</strong> {formatOrderDescription(ticket) || 'N/A'}
                </Card.Text>
                <Card.Text>
                    <strong>PO Number:</strong> {ticket.po_number || 'N/A'}
                </Card.Text>
                <Card.Text className="d-flex justify-content-between">
                    <span><strong>{ticket.product_description}</strong></span>
                    <span className="text-muted">{formatQuantity(ticket)}</span>
                </Card.Text>
            </Card.Body>
            <Card.Footer className="text-center bg-white">
                <Button variant="link" href={`/tickets/${ticket.ticket_id}`}>View Full Ticket</Button>
            </Card.Footer>
        </Card>
    );
};

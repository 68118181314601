import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import './InfoIcon.scss';

export default function InfoIcon({ text, ...props }) {
    return (
        <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{text}</Tooltip>}
        >
            <span className={(props.className || "") + " info-icon mx-1"}><i className="bi bi-info-circle" /></span>
        </OverlayTrigger>
    );
}
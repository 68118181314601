
export const formatArrayFilter = (filter) =>
    filter.reduce((acc, curr) => {
        if(acc === '') {
            return curr;
        }
        return `${acc};${curr}`
    }, '');

export const sortByProperty = (a, b, property, asc= true) => {
    if(a[property] > b[property]) {
        return asc ? 1 : -1;
    } else if(a[property] < b[property]) {
        return asc ? -1 : 1;
    }
    return 0;
}

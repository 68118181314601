import { Container, Nav, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import Navigation from '../navigation/Navigation';

import './DashboardPage.scss';

export default function DashboardPage(props) {
    return (
        <>
            <Navigation />
            <div id="Dashboard">
                <div id="Dashboard-Nav" className="fixed-top">
                    <Container className="d-md-none pt-3">
                        <Nav>
                            <Nav.Item>
                                <NavLink className="custom-nav-link" to="/invoices"><h2>Invoices</h2></NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="custom-nav-link" to="/tickets"><h2>Tickets</h2></NavLink>
                            </Nav.Item>
                        </Nav>
                    </Container>
                    <div className="d-none d-md-block pt-3">
                        <Nav>
                            <Nav.Item>
                                <NavLink className="custom-nav-link" to="/invoices"><h2>Invoices</h2></NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="custom-nav-link" to="/tickets"><h2>Tickets</h2></NavLink>
                            </Nav.Item>
                        </Nav>
                    </div>
                </div>
                <Container id="Dashboard-Body" className="d-md-none">
                    {props.children}
                </Container>
                <div id="Dashboard-Body" className="d-none d-md-block">
                    {props.children}
                </div>
            </div>
        </>
    );
};

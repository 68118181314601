export const LOCAL = "local";
export const DEV = "dev";
export const PROD = "prod";

function getEnvironment() {
  const domain = window.location.hostname.replace("www.", "");

  if (domain === "localhost" || domain === "127.0.0.1") {
    return LOCAL;
  } else if (domain === "dev.msczone.com") {
    return DEV;
  } else if (domain === "msczone.com") {
    return PROD;
  }

  console.error(`Domain name not recognized: ${domain}`);
  return "";
}

export const ENVIRONMENT = getEnvironment();

export function getFullName(user, fallback) {
    const nameParts = [];
    if (!!user.first_name) nameParts.push(user.first_name);
    if (!!user.last_name) nameParts.push(user.last_name);
    
    if (nameParts.length === 0) {
        if (fallback) {
            return fallback;
        } else {
            return user.email;
        }
    } else {
        return nameParts.join(" ");
    }
}

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getTicket } from '../../apis/ticketsApi';

import PrintButton from '../../components/styled-components/print-button/PrintButton';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';

import { AuthContext } from '../../providers/authProvider';

import { prettyNumber, prettyPrice } from '../../utils/numberFormatter';
import { formattedDate, formattedTime } from '../../utils/dateFormatter';
import { formatCustomerNameAndId, formatOrderDescription, formatPricePerUnit, formatQuantity } from '../../utils/ticketFormatter';

export default function TicketDetail({passedTicket= null}) {
    const { ticketId } = useParams();
    const { token } = useContext(AuthContext);

    const [isTicketDetailLoading, setIsTicketDetailLoading] = useState(true);
    const [hasTicketDetailError, setHasTicketDetailError] = useState(false);

    const [ticketDetail, setTicketDetail] = useState({});

    const handleTicketDetailSuccess = (res) => {
        setIsTicketDetailLoading(false);
        setTicketDetail(res);
    }

    const handleTicketDetailError = (err) => {
        setIsTicketDetailLoading(false);
        setHasTicketDetailError(true);
    }

    useEffect(() => {
        setIsTicketDetailLoading(true);
    }, [passedTicket]);

    useEffect(() => {
        let ticket = !!passedTicket ? passedTicket.id : ticketId;

        if (isTicketDetailLoading) {
            getTicket({ ticketId: ticket, token })
                .then(handleTicketDetailSuccess, handleTicketDetailError)
                .catch(handleTicketDetailError);
        }
    }, [isTicketDetailLoading]);

    function TicketDetailRow({ title, info, ...props }) {
        return (
            <p className="d-flex justify-content-between">
                <span>
                    <strong>{title}: </strong>{info || '--'}
                </span>
                {!!props.secondaryInfo &&
                    <span>{props.secondaryInfo}</span>
                }
            </p>
        )
    }

    function renderFormattedDate() {
        const { ticket_date } = ticketDetail;
        return ticket_date && formattedDate(ticket_date);
    }

    function renderFormattedTime() {
        const { ticket_time } = ticketDetail;
        return ticket_time && formattedTime(ticket_time);
    }

    function renderTicketDetails() {
        return (
            <div id="Ticket-Detail">
                <h2 className="mb-3 d-flex justify-content-between d-md-none">
                    Ticket
                    <PrintButton />
                </h2>
                <TicketDetailRow
                    title="Plant"
                    info={ticketDetail.plant_name}
                    secondaryInfo={renderFormattedDate()}
                />
                <TicketDetailRow
                    title="Ticket #"
                    info={ticketDetail.ticket_number}
                    secondaryInfo={renderFormattedTime()}
                />
                <TicketDetailRow
                    title="Customer"
                    info={formatCustomerNameAndId(ticketDetail)}
                />
                <TicketDetailRow
                    title="PO Number"
                    info={ticketDetail.po_number}
                />
                <TicketDetailRow
                    title="Order"
                    info={formatOrderDescription(ticketDetail)}
                />
                {!!ticketDetail.invoice_number &&
                    <TicketDetailRow
                        title="Invoice"
                        info={ticketDetail.invoice_number}
                    />
                }
                <hr />
                <TicketDetailRow
                    title="Product"
                    info={ticketDetail.product_description}
                />
                <TicketDetailRow
                    title="Quantity"
                    info={formatQuantity(ticketDetail)}
                />
                <TicketDetailRow
                    title="Amount"
                    info={prettyPrice(ticketDetail.price)}
                />
                <TicketDetailRow
                    title="Price"
                    info={formatPricePerUnit(ticketDetail, 'material_rate')}
                />
                <TicketDetailRow
                    title="Freight"
                    info={formatPricePerUnit(ticketDetail, 'freight_rate')}
                />
                <hr />
                <TicketDetailRow
                    title="Carrier"
                    info={ticketDetail.carrier_name}
                />
                <TicketDetailRow
                    title="Vehicle ID"
                    info={ticketDetail.vehicle_id}
                />
                <TicketDetailRow
                    title="Gross"
                    info={prettyNumber(ticketDetail.gross, 0)}
                />
                <TicketDetailRow
                    title="Tare"
                    info={prettyNumber(ticketDetail.tare, 0)}
                />
                <TicketDetailRow
                    title="Net"
                    info={prettyNumber(ticketDetail.net, 0)}
                />
            </div>
        );
    }

    return (
        <>
            {isTicketDetailLoading ?
                <LoadingSpinner typ="page" /> :
                renderTicketDetails()
            }
        </>
    );
};

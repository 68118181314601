import React, { createContext, useState } from 'react';

const initState = {
    filters: {
        dateRange: {},
        plantId: undefined,
        product: undefined,
        order: undefined
    },
    searchTerm: undefined
}

export const TicketFiltersContext = createContext(initState);

export const TicketFiltersProvider = (props) => {
    const setFilters = (newFilters) => {
        setState(prevState => {
            return ({
                ...prevState,
                filters: newFilters
            });
        });
    }

    const setSearchTerm = (newSearchTerm) => {
        setState(prevState => ({
            ...prevState,
            searchTerm: newSearchTerm
        }))
    }

    const resetFilters = () => {
        setState({...initState, setFilters, setSearchTerm, resetFilters});
    }

    const [state, setState] = useState({...initState, setFilters, setSearchTerm, resetFilters});

    return (
        <TicketFiltersContext.Provider value={state}>
            {props.children}
        </TicketFiltersContext.Provider>
    )
}

export function generateTelLink(phone) {
    return `tel:${phone}`;
}

export function generateFaxLink(fax) {
    return `fax:+${fax}`;
}

export function generateMailToLink(email, subject, body) {
    const cleanSubject = encodeURIComponent(subject || 'MSC Zone Issue');
    const cleanBody = encodeURIComponent(body || '');
    
    return `mailto:${email}?subject=${cleanSubject}&body=${cleanBody}`;
}

export function generateGoogleMapsLink(address, placeId) {
    const cleanAddress = encodeURIComponent(address || '');
    const oPlaceIdParam = placeId ? `&query_place_id=${placeId}` : '';
    return `https://www.google.com/maps/search/?api=1&query=${cleanAddress}${oPlaceIdParam}`;
}
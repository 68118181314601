import React from 'react';
import './ContactPage.scss';
import DetailPage from '../detail-page/DetailPage';
import { generateTelLink, generateMailToLink, generateFaxLink, generateGoogleMapsLink } from '../../utils/contactLinkFormatter';
import { Row } from 'react-bootstrap';

export default function ContactPage(props) {
    const phone = "704-633-4241";
    const email = "mscinvoices@hedrickind.com";
    const fax = "704-630-0040";
    const streetAddress = "120 1/2 N Church St"
    const cityAddress = "Salisbury, NC 28144"
    const googlePlaceId = "ChIJOxY2v9jyU4gRSRp0CIEuaDI";

    return (
        <div id="Contact-Page">
            <DetailPage breadcrumb={{ route: "/", title: "Dashboard" }}>
                <h1>Contact Us</h1>
                <hr />
                <address>
                    <Row>
                        <h5>Mailing Address</h5>
                        <p>
                            <a href={generateGoogleMapsLink(`${streetAddress} ${cityAddress}`, googlePlaceId)}>
                                {streetAddress} <br />
                                {cityAddress}
                            </a>
                        </p>
                    </Row>
                    <Row>
                        <h5>Email</h5>
                        <p>
                            <a href={generateMailToLink(email, "")}>{email}</a>
                        </p>
                    </Row>
                    <Row>
                        <h5>Telephone</h5>
                        <p>
                            <a href={generateTelLink(phone)}>{phone}</a>
                        </p>
                    </Row>
                    <Row>
                        <h5>Fax</h5>
                        <p>
                            <a href={generateFaxLink(fax)}>{fax}</a>
                        </p>
                    </Row>
                </address>
            </DetailPage>
        </div>
    )
}

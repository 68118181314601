import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import { LicenseInfo } from "@mui/x-license-pro";

import Login from "./components/login/Login";

import { AuthContext } from "./providers/authProvider";
import AccountManagement from "./components/account-management/AccountManagement";
import DashboardPage from "./components/dashboard-page/DashboardPage";
import DetailPage from "./components/detail-page/DetailPage";
import InvoiceDetail from "./components/invoice-detail/InvoiceDetail";
import InvoiceHistory from "./components/invoice-history/InvoiceHistory";
import TicketHistory from "./components/ticket-history/TicketHistory";
import TicketDetail from "./components/ticket-detail/TicketDetail";
import SignUp from "./components/sign-up/SignUp";
import ContactPage from "./components/contact-page/ContactPage";
import TonnageSummaryMobile from "./components/tonnage-summary/TonnageSummaryMobile";
import ResetPassword from "./components/reset-password/ResetPassword";

import SECRETS from "./secrets.json";
import { getPlants } from "./apis/plantsApi";
import { PlantsContext } from "./providers/plantsProvider";

LicenseInfo.setLicenseKey(SECRETS.MUI_X_PRO_LICENSE_KEY);

export default function App() {
  const DEFAULT_PATH = "/invoices";
  const [isTokenLoading, setIsTokenLoading] = useState(true);

  const { token, refreshToken } = useContext(AuthContext);
  const { setPlants } = useContext(PlantsContext);

  useEffect(() => {
    if (!token) {
      // If we don't have a JWT token, attempt a silent refresh.
      refreshToken()
        .then(
          (res) => setIsTokenLoading(false),
          (err) => setIsTokenLoading(false)
        )
        .catch((err) => setIsTokenLoading(false));
    } else {
      setIsTokenLoading(false);
    }
  }, [token, refreshToken]);

  useEffect(() => {
    let isMounted = true;
    getPlants({ token }).then((res) => {
      if (isMounted) {
        setPlants(res);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  // TODO: Return a loading spinner while we sort out our authorization.
  if (isTokenLoading) {
    return <div />;
  }

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route {...rest}>{!!token ? children : <Redirect to="/login" />}</Route>
    );
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/reset-password/:uid/:resetToken"
          component={ResetPassword}
        />

        <PrivateRoute exact path="/">
          <Redirect to={DEFAULT_PATH} />
        </PrivateRoute>
        <PrivateRoute exact path="/tickets">
          <DashboardPage children={<TicketHistory />} />
        </PrivateRoute>
        <PrivateRoute path="/tickets/:ticketId">
          <DetailPage
            breadcrumb={{ route: "/tickets", title: "Tickets" }}
            children={<TicketDetail />}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/invoices">
          <DashboardPage children={<InvoiceHistory />} />
        </PrivateRoute>
        <PrivateRoute path="/invoices/:invoiceId">
          <DetailPage
            breadcrumb={{ route: "/invoices", title: "Invoices" }}
            children={<InvoiceDetail />}
          />
        </PrivateRoute>
        <PrivateRoute path="/settings/account-management">
          <AccountManagement />
        </PrivateRoute>
        <PrivateRoute path="/contact-us">
          <ContactPage />
        </PrivateRoute>
        <PrivateRoute exact path="/tonnage-summary">
          <DetailPage
            breadcrumb={{ route: "/tickets", title: "Tickets" }}
            children={<TonnageSummaryMobile />}
          />
        </PrivateRoute>

        {/* Handle invalid paths */}
        <Route render={() => <Redirect to={{ pathname: DEFAULT_PATH }} />} />
      </Switch>
    </Router>
  );
}

import { Row } from 'react-bootstrap';

import './CardSeparator.scss';

export default function CardSeparator({ text, key = text, ...props }) {
    return (
        <Row className="card-separator mb-3" key={key} >
            <p>{text}</p>
        </Row>
    );
}
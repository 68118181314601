import React, { useContext } from 'react';
import { Button, Card } from 'react-bootstrap';

import './InvoiceHistoryCard.scss';

import PaymentFormButton from '../payment-form-button/PaymentFormButton';
import InvoiceStatus from '../../components/styled-components/invoice-status/InvoiceStatus';

import { getPlantName } from '../../constants/plants';

import { formattedDate } from '../../utils/dateFormatter';
import { prettyNumber, prettyPrice } from '../../utils/numberFormatter';
import { formatCustomerNameAndId, formatOrderDescription } from '../../utils/invoiceFormatter';
import { PlantsContext } from '../../providers/plantsProvider';

export default function InvoiceHistoryCard({
    invoice,
    status,
    onInitiatePayment,
    ...props
}) {
    const {plants: plantsList } = useContext(PlantsContext);
    const isPaymentPending = status === 'pending';
    const isPaymentProcessing = invoice.isProcessing;

    const allInvoiceProducts = (invoice.products || []);
    const topInvoiceProducts = allInvoiceProducts.slice(0, 3);
    const remainingProductsCount = allInvoiceProducts.length - topInvoiceProducts.length;

    return (
        <Card className="Invoice-History-Card mb-3 mx-3">
            <Card.Body>
                <Card.Text as="div" className="mb-3">
                    <div className="d-flex justify-content-between">
                        <span><strong>Invoice #:</strong> {invoice.invoice_number}</span>
                        <span className="text-muted">{formattedDate(invoice.date)}</span>
                    </div>
                    <div>
                        <strong>Plant:&nbsp;</strong>
                        <span className="text-capitalize">{getPlantName(invoice.plant_id, plantsList).toLocaleLowerCase()}</span>
                    </div>
                    <div>
                        <strong>Status:&nbsp;</strong>
                        <InvoiceStatus invoice={invoice} />
                    </div>
                    {
                        invoice.isPending &&
                        <div>
                            <strong>Due Date:&nbsp;</strong>
                            {formattedDate(invoice.due_date)}
                        </div>
                    }
                    <div className="mt-3">
                        <div>
                            <strong>Customer:&nbsp;</strong>
                            <span>{formatCustomerNameAndId(invoice) || 'N/A'}</span>
                        </div>
                        <div>
                            <strong>Order:&nbsp;</strong>
                            <span>{formatOrderDescription(invoice) || 'N/A'}</span>
                        </div>
                        <div>
                            <strong>PO Number:&nbsp;</strong>
                            <span>{invoice.po_number || 'N/A'}</span>
                        </div>
                    </div>
                </Card.Text>
                <Card.Text as="div" className="mb-3">
                    <div>
                        <strong className="text-muted fs-7 mb-1">
                            {invoice.total_tickets} Tickets | {invoice.total_products} Products
                        </strong>
                    </div>
                    <div className="mb-1">
                        {
                            topInvoiceProducts.map((product, i) => {
                                return (
                                    <div key={`product-${i}`}>
                                        <span>{product.ticket_count} x {product.product_description}</span>
                                        &nbsp;
                                        <span className="text-muted">({prettyNumber(product.product_quantity)} T)</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        !!remainingProductsCount &&
                        <div className="text-muted fs-7">
                            +{remainingProductsCount} more
                        </div>
                    }
                </Card.Text>
                <Card.Text as="div">
                    <strong className="fs-5">
                        {isPaymentPending ?
                            <>
                                <span>Amount Due&nbsp;</span>
                                <span>{prettyPrice(invoice.total_amount_due)}</span>
                            </> :
                            <>
                                <span>Amount Paid&nbsp;</span>
                                <span>{prettyPrice(invoice.total_amount)}</span>
                            </>
                        }
                    </strong>
                </Card.Text>
            </Card.Body>
            {
                isPaymentPending ?
                    <Card.Footer className="d-flex justify-content-around bg-white">
                        <PaymentFormButton
                            invoices={[invoice]}
                            buttonText={"Pay Invoice"}
                            buttonSize={""}
                            buttonVariant={"link"}
                        />
                        <div className="vertical-spacer"></div>
                        <Button variant="link" href={`/invoices/${invoice.invoice_id}`}>View Invoice</Button>
                    </Card.Footer>
                    :
                    <Card.Footer className="d-flex justify-content-center bg-white">
                        <Button variant="link" href={`/invoices/${invoice.invoice_id}`}>View Invoice</Button>
                    </Card.Footer>
            }
        </Card >
    );
};

import React, { useContext, useEffect, useState } from 'react';
import {
    useHistory,
    useRouteMatch,
    NavLink,
    Route,
} from 'react-router-dom';
import {
    Button,
    Col,
    Container,
    Row,
    Table,
} from 'react-bootstrap';

import './AccountManagement.scss';

import { addCustomerProfile, getCustomerProfiles } from '../../apis/authenticationApi';
import { getPlantName } from '../../constants/plants';
import DetailPage from '../detail-page/DetailPage';
import Navigation from '../navigation/Navigation';
import CustomerProfileForm from '../customer-profile-form/CustomerProfileForm';
import UpdatePassword from '../update-password/UpdatePassword';
import { AuthContext } from '../../providers/authProvider';
import { PlantsContext } from '../../providers/plantsProvider';

export default function AccountManagement(props) {
    const { path } = useRouteMatch();
    const { token } = useContext(AuthContext);
    const {plants} = useContext(PlantsContext);
    const history = useHistory();

    const [customerProfiles, setCustomerProfiles] = useState({
        profiles: [],
        isLoading: true,
    });

    useEffect(() => {
        if (customerProfiles.isLoading) {
            getCustomerProfiles({ token }).then(
                (profiles) => {
                    setCustomerProfiles({
                        profiles,
                        isLoading: false,
                    })
                }
            );
        }
    }, [customerProfiles.isLoading]);

    function handleNewCustomerProfile(submittedInvoiceDetails) {
        const { invoiceNumber, customerNumber, plantId } = submittedInvoiceDetails;

        if (invoiceNumber && customerNumber && plantId) {
            addCustomerProfile({
                invoiceNumber,
                customerNumber,
                plantId,
                token,
            }).then((res) => {
                // TODO: Adjust success handling
                history.go(0);
            }).catch((err) => {
                // TODO: Error handling
            });
        }
    }

    function renderCustomerProfiles() {
        const { profiles } = customerProfiles;

        return (
            <>
                <Table responsive bordered className="mb-3">
                    <thead className="table-light">
                        <tr>
                            <th>Plant Name</th>
                            <th>Customer Id</th>
                        </tr>
                    </thead>
                    <tbody className="text-nowrap">
                        {profiles.map((profile) => {
                            return (
                                <tr key={`${profile.plant_id}-${profile.customer_id}`}>
                                    <td>{getPlantName(profile.plant_id, plants)}</td>
                                    <td>{profile.customer_id}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </>
        );
    }

    function renderAddCustomerPage() {
        return (
            <Row className="align-items-center justify-content-center">
                <h1>Current Customer Profiles</h1>
                {renderCustomerProfiles()}
                <hr />
                <Col xs={12} md={6} lg={4}>
                    <h1>Grab your last invoice to get started.</h1>
                    <div className="mb-3">
                        First, please find a previous MSC invoice.
                        We'll use the information on this invoice to confirm your account.
                    </div>
                    <CustomerProfileForm
                        handleSubmit={handleNewCustomerProfile}
                        btnText="Add Customer Profile"
                    />
                </Col>
            </Row>
        );
    }

    function renderAccountSettingsPage() {
        return (
            <>
                <h1>Account Settings</h1>
                <hr />
                <h5>Current Customer Profiles</h5>
                {renderCustomerProfiles()}
                <Row className="align-items-center justify-content-center">
                    <Col xs={12} md={6} lg={4}>
                        <div className="d-grid gap-2">
                            <Button variant="primary" size="lg">
                                <NavLink className="text-white text-decoration-none" to={`${path}/customer-profile`}>Add Customer Profiles</NavLink>
                            </Button>
                            <hr />
                            <Button variant="primary" size="lg">
                                <NavLink className="text-white text-decoration-none" to={`${path}/reset-password`}>Reset Password</NavLink>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <div id="Account-Management">
            <Route exact path={path}>
                <DetailPage breadcrumb={{ route: "/", title: "Dashboard" }} children={renderAccountSettingsPage()} />
            </Route>
            <Route path={`${path}/customer-profile`}>
                <DetailPage breadcrumb={{ route: path, title: "Account Settings" }} children={renderAddCustomerPage()} />
            </Route>
            <Route path={`${path}/reset-password`}>
                <DetailPage breadcrumb={{ route: path, title: "Account Settings" }} children={<UpdatePassword />} />
            </Route>
        </div>
    )
}

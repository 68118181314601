import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMediaQuery from "@mui/material/useMediaQuery";

import './TicketHistory.scss';

import { getTickets } from '../../apis/ticketsApi';
import { MOBILE_SCREEN_MAX_WIDTH } from "../../constants/miscConstants";
import { PAGES } from '../search-filters-input/SearchFiltersDesktop';

import CardSeparator from '../../components/styled-components/card-separator/CardSeparator';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import TicketHistoryCard from '../../components/ticket-history-card/TicketHistoryCard';
import SearchFiltersModal from '../../components/search-filters-modal/SearchFiltersModal';

import { AuthContext } from '../../providers/authProvider';
import SearchFiltersInput from '../search-filters-input/SearchFiltersInput';
import TicketHistoryDesktop from "./TicketHistoryDesktop";
import {Button} from "react-bootstrap";
import {TicketFiltersContext} from "../../providers/ticketFiltersProvider";

export default function TicketHistory() {
    const { token } = useContext(AuthContext);
    const { filters, setFilters, searchTerm, setSearchTerm, resetFilters } = useContext(TicketFiltersContext);
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_SCREEN_MAX_WIDTH}px)`, {
        noSsr: true
    });

    const initState = {
        tickets: [],
        offset: 0,
        pageSize: 10,
        isLoading: false,
        isFirstPageLoaded: false,
        isLastPageLoaded: false,
        hasError: false
    };

    const [ticketHistory, setTicketHistory] = useState(initState);


    const [filterModal, setFilterModal] = useState({ show: false });

    const handleFiltersClose = () => setFilterModal({ show: false });
    const handleFiltersShow = () => setFilterModal({ show: true });

    useEffect(() => {
        if (!ticketHistory.isFirstPageLoaded && isMobile) {
            fetchTickets();
        }
    }, [ticketHistory.isFirstPageLoaded, isMobile]);


    //
    // API Calls
    // 

    function fetchTickets() {
        const { isLoading, isLastPageLoaded, offset, pageSize } = ticketHistory;
        const { dateRange, plantId, product, order } = filters;
        const { startDate, endDate } = dateRange;

        if (!isLoading && !isLastPageLoaded) {
            setTicketHistory({ ...ticketHistory, isLoading: true });
            getTickets({
                startDate: startDate?.toLocaleDateString() || undefined,
                endDate: endDate?.toLocaleDateString() || undefined,
                searchTerm,
                plantId,
                products: product,
                orders: order,
                offset,
                limit: pageSize,
                token,
            })
                .then(handleTicketHistorySuccess, handleTicketHistoryError)
                .catch(handleTicketHistoryError);
        }
    }

    const handleTicketHistorySuccess = (res) => {
        setTicketHistory({
            ...ticketHistory,
            tickets: ticketHistory.tickets.concat(res),
            offset: ticketHistory.offset + res.length,
            isLoading: false,
            isFirstPageLoaded: true,
            isLastPageLoaded: res.length < ticketHistory.pageSize
        });
    }

    const handleTicketHistoryError = (err) => {
        setTicketHistory({
            ...ticketHistory,
            isLoading: false,
            hasError: true,
        });
    }

    // 
    // Private Helpers
    // 

    function resetTicketHistory() {
        setTicketHistory(initState);
    }

    const handleSetFilters = (newFilters) => {
        handleFiltersClose();
        setTicketHistory(initState);
        setFilters(newFilters);
    }

    const handleResetFilters = () => {
        handleFiltersClose();

        resetTicketHistory();
        resetFilters();
    }

    const handleSearchTickets = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setTicketHistory(initState);
    }

    function hasFilters() {
        const { dateRange, plantId, order, product } = filters;

        return !!dateRange.startDate || !!plantId || !!order || !!product;
    }

    // 
    // Render Helpers
    // 

    function renderCard(ticket) {
        return (
            <TicketHistoryCard
                ticket={ticket}
                key={ticket.ticket_id}
            />
        );
    }

    function renderGroupedCards() {
        let lastDateLabel = undefined;

        return (
            <>
                {ticketHistory.tickets.map((ticket) => {
                    const newDateLabel = format(new Date(ticket.ticket_date), 'MMMM yyyy');

                    // If we've encountered a new month, 
                    // add a month banner to the list.
                    if (newDateLabel !== lastDateLabel) {
                        lastDateLabel = newDateLabel;
                        return (
                            <>
                                <CardSeparator text={newDateLabel} />
                                {renderCard(ticket)}
                            </>
                        );
                    } else {
                        return renderCard(ticket);
                    }
                })}
            </>
        );
    }

    function renderSearchBar() {
        return (
            <SearchFiltersInput
                searchTerm={searchTerm}
                onSearchSubmit={handleSearchTickets}
                onSetSearchTerm={setSearchTerm}
                onShowFilters={handleFiltersShow}
                onResetFilters={handleResetFilters}
                hasFilters={hasFilters}
                inputLabel="Search for tickets"
                inputPlaceholder="Search by ticket, po, order, or product"
            ></SearchFiltersInput>
        )
    }

    function renderFilterModal() {
        return (
            <SearchFiltersModal
                initFilters={filters}
                show={filterModal.show}
                onHide={handleFiltersClose}
                onApplyFilters={handleSetFilters}
                onClearFilters={handleResetFilters}
                title="Filter Tickets"
                page={PAGES.Tickets}
            ></SearchFiltersModal>
        );
    }

    function renderInfiniteScroll() {
        const { tickets, isFirstPageLoaded, isLastPageLoaded } = ticketHistory;
        return (
            <InfiniteScroll
                dataLength={tickets.length} // This is important field to render the next data
                next={fetchTickets}
                hasMore={!isLastPageLoaded}
                loader={<div><LoadingSpinner typ={isFirstPageLoaded ? 'infinite-scroll' : 'page'} />&nbsp;</div>}
                scrollableTarget="Dashboard-Body"
                endMessage={
                    <p className="text-center">
                        <b>No More Results</b>
                    </p>
                }
            >
                {renderGroupedCards()}
            </InfiniteScroll>
        );
    }

    return (
        <>
            {isMobile ? (
                <div id="Ticket-History">
                    {renderSearchBar()}
                    <div className="d-flex flex-row justify-content-center mb-3">
                        <Link
                            style={{textDecoration: 'none'}}
                            to={`/tonnage-summary`}
                        >View Tonnage Summary</Link>
                    </div>
                    {renderFilterModal()}
                    {renderInfiniteScroll()}
                </div>
            ): (
                <div style={{height: "100%"}}>
                    <TicketHistoryDesktop />
                </div>
            )}
        </>
    );
};

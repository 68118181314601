import { prettyNumber, prettyPrice } from './numberFormatter';

export function formatOrderDescription(ticket) {
    const { order_id, order_description_1 } = ticket;
    const showDescription = !!order_description_1 && order_description_1 !== order_id;

    if (!order_id) {
        return '';
    } else if (showDescription) {
        return `${order_description_1} (#${order_id})`;
    } else {
        return order_id;
    }
}

export function formatCustomerNameAndId(ticket) {
    const { customer_name, customer_id } = ticket;

    if (!!customer_name && !!customer_id) {
        return `${customer_name} (#${customer_id})`;
    } else {
        return customer_name || customer_id || '';
    }
}

export function formatQuantity(ticket) {
    const { qty, unit } = ticket;
    let formattedUnit;

    switch (unit) {
        case 'Ton':     
            return `${prettyNumber(qty, 2)} Tons`;
        case 'Load':
            formattedUnit = (qty === 1) ? 'Load' : 'Loads';
            return `${prettyNumber(qty, 0)} ${formattedUnit}`;
        default: 
            formattedUnit = unit || 'Units';
            return `${prettyNumber(qty, 2)} ${formattedUnit}`;
    }
}

export function formatPricePerUnit(ticket, amountField) {
    const field_to_unit = {
        'material_rate': ticket.material_unit,
        'freight_rate': ticket.freight_unit
    }
    const unit = field_to_unit[amountField];
    const price = ticket[amountField];
    
    let formattedUnit = unit || 'Unit';

    switch (unit) {
        case 'T':
            formattedUnit = 'Ton'; break;
        case 'L':
            formattedUnit = 'Load'; break;
    }

    return `${prettyPrice(price)} / ${formattedUnit}`;
}
import React, {useState} from "react";
import { NavLink, useParams } from 'react-router-dom';

import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    Modal,
    Row,
    InputGroup
} from 'react-bootstrap';

import Navigation from '../navigation/Navigation';
import { resetPassword } from '../../apis/authenticationApi';

import './ResetPassword.scss';

const ResetPassword = () => {
    const {uid, resetToken} = useParams();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};
        if(!email) {
            newErrors = {email: 'Email is required'};
        }
        if(!password) {
            newErrors = {...newErrors, password: 'Password is required'}
        }
        if(!confirmPassword) {
            newErrors = {...newErrors, confirmPassword: 'Confirm password is required'}
        }

        if(Object.values(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }


        resetPassword({
            uid,
            resetToken,
            email,
            password,
            confirmPassword
        }).then(res => {
            setErrors({});
            setSuccess(true);
        }).catch(err => {
            setErrors(err.response.data);
        });
    }

    if(success) {
        return (
            <div id="Login">
            <Navigation />
            <Container>
                <Row className="align-items-center justify-content-center vh-100">
                    <Col xs={12} md={6} lg={4} className="px-4">
                        <h1 className="mb-4 text-center">
                            Password successfully reset!
                        </h1>
                        <NavLink className="text-center" to="/login"><h4>Log in with your new password here.</h4></NavLink>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }

    return (
        <div id="Login">
            <Navigation />
            <Container>
                <Row className="align-items-center justify-content-center vh-100">
                    <Col xs={12} md={6} lg={4} className="px-4">
                        <h1 className="mb-4">
                            Reset your M.S.C. Zone password
                        </h1>
                        {errors.general ? (
                            <Alert variant='danger'>
                                {errors.general}
                            </Alert>
                        ) : (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="d-flex align-items-center justify-content-between">
                                        <span>Email Address</span>
                                    </Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="text"
                                            placeholder="john.smith@gmail.com"
                                            onChange={e => setEmail(e.target.value)}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </InputGroup>

                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label className="d-flex align-items-center justify-content-between">
                                        <span>New Password</span>
                                    </Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="password"
                                            placeholder="**************"
                                            onChange={e => setPassword(e.target.value)}
                                            isInvalid={!!errors.password}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </InputGroup>

                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label className="d-flex align-items-center justify-content-between">
                                        <span>Confirm Password</span>
                                    </Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="password"
                                            placeholder="**************"
                                            onChange={e => setConfirmPassword(e.target.value)}
                                            isInvalid={!!errors.confirmPassword}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.confirmPassword}
                                        </Form.Control.Feedback>
                                    </InputGroup>

                                </Form.Group>
                                <div className="d-grid pt-2">
                                    <Button variant="primary" type="submit" size="lg">Submit</Button>
                                </div>
                            </Form>
                        )}

                        <div className="text-center mt-4">
                            <span>Remembered your password?</span><br />
                            <NavLink to="/login">Sign in here</NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default ResetPassword;

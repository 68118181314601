import { Button } from 'react-bootstrap';

import './PrintButton.scss';

export default function PrintButton({ onClickFn, ...props }) {
    function onClickPrint() {
        if (typeof onClickFn === 'function') {
            onClickFn();
        } else {
            window.print();
        }
    }

    return (
        <Button 
            className="no-print" 
            variant="outline-secondary" 
            onClick={onClickPrint}
        >
            <i className="bi bi-printer"/> Print
        </Button>
    );
}
import React, { useContext, useEffect, useState } from 'react';
import { Button, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import './InvoiceDetail.scss';

import { getInvoice, getInvoiceTickets } from '../../apis/invoicesApi';
import { getProcessingPayments, expireProcessingPayments, expireProcessingPaymentsByInvoice } from '../../apis/paymentsApi';

import { getPlantName } from '../../constants/plants';

import PrintButton from '../../components/styled-components/print-button/PrintButton';
import PaymentFormButton from '../payment-form-button/PaymentFormButton';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import InvoiceStatus from '../../components/styled-components/invoice-status/InvoiceStatus';

import { AuthContext } from '../../providers/authProvider';
import { PaymentContext } from '../../providers/paymentProvider';

import { prettyPrice } from '../../utils/numberFormatter';
import { formattedDate } from '../../utils/dateFormatter';
import { augmentInvoice, isProcessingPaymentExpired } from '../../utils/invoiceFormatter';
import { formatOrderDescription, formatCustomerNameAndId } from '../../utils/ticketFormatter';
import { PlantsContext } from '../../providers/plantsProvider';

const initialInvoiceTicketsState = {
    tickets: [],
    totalTickets: 0,
    offset: 0,
    pageSize: 5,
    isLoading: false,
    isLoadingAll: false,
    isFirstPageLoaded: false,
    isLastPageLoaded: false,
    printOnLastPage: false,
    hasError: false,
};

export default function InvoiceDetail({passedInvoiceId = '', onTicketClick}) {
    const { invoiceId } = useParams();
    const { token } = useContext(AuthContext);
    const { newProcessingInvoices } = useContext(PaymentContext);
    const { plants } = useContext(PlantsContext);

    const [isInvoiceDetailLoading, setIsInvoiceDetailLoading] = useState(true);
    const [hasInvoiceDetailError, setHasInvoiceDetailError] = useState(false);

    const [invoiceDetail, setInvoiceDetail] = useState();

    const [invoiceTickets, setInvoiceTickets] = useState({
        tickets: [],
        totalTickets: 0,
        offset: 0,
        pageSize: 5,
        isLoading: false,
        isLoadingAll: false,
        isFirstPageLoaded: false,
        isLastPageLoaded: false,
        printOnLastPage: false,
        hasError: false,
    });

    const [processingPayments, setProcessingPayments] = useState({
        payments: [],
        isLoading: true,
        hasError: false,
    });

    useEffect(() => {
        if(passedInvoiceId) {
            setIsInvoiceDetailLoading(true);
            setInvoiceTickets(initialInvoiceTicketsState);
            fetchInvoiceDetails(passedInvoiceId);
        }
    }, [passedInvoiceId])

    useEffect(() => {
        if (processingPayments.isLoading) {
            fetchProcessingPayments();
        }
    }, [processingPayments.isLoading]);

    useEffect(() => {
        if (!processingPayments.isLoading && isInvoiceDetailLoading && invoiceId) {
            fetchInvoiceDetails();
        }
    }, [isInvoiceDetailLoading, processingPayments.isLoading, invoiceId]);

    useEffect(() => {
        if (!invoiceTickets.isFirstPageLoaded) {
            fetchInvoiceTickets();
        }
    }, [invoiceTickets.isFirstPageLoaded]);

    useEffect(() => {
        // TODO: Update state more granularly, rather than refreshing.
        if ((newProcessingInvoices || []).length > 0) {
            window.location.reload(false);
        }
    }, [newProcessingInvoices]);

    // When isLoadingAll is set to true, start loading in a new page after 
    // each page load (until we've loaded all pages).
    useEffect(() => {
        if (invoiceTickets.isLoadingAll) {
            fetchInvoiceTickets();
        }
    }, [invoiceTickets.isLoadingAll, invoiceTickets.tickets]);

    useEffect(() => {
        if (invoiceTickets.printOnLastPage && invoiceTickets.isLastPageLoaded) {
            window.print();
            setInvoiceTickets({ ...invoiceTickets, printOnLastPage: false });
        }
    }, [invoiceTickets.printOnLastPage, invoiceTickets.isLastPageLoaded]);

    // 
    // API Calls
    //

    function fetchProcessingPayments(p) {
        getProcessingPayments({
            token,
        })
            .then(handleProcessingPaymentsSuccess, handleProcessingPaymentsError)
            .catch(handleProcessingPaymentsError);
    }

    const handleProcessingPaymentsSuccess = (res) => {
        res.forEach((payment) => {
            if (isProcessingPaymentExpired(payment)) {
                // If we find any expired payments, send a call to the API to clear them. 
                payment.isExpired = true;
                expireProcessingPayments({ payment, token });
            }
        })

        setProcessingPayments({
            payments: res,
            isLoading: false,
        });
    }

    const handleProcessingPaymentsError = (err) => {
        setProcessingPayments({
            ...processingPayments,
            isLoading: false,
            hasError: true,
        });
    }

    function fetchInvoiceDetails(invoice = invoiceId) {
        getInvoice({ invoiceId: invoice, token })
            .then(handleInvoiceDetailSuccess, handleInvoiceDetailError)
            .catch(handleInvoiceDetailError);
    }

    const handleInvoiceDetailSuccess = (res) => {
        // Augment the invoice with calculated fields (e.g. isProcessing)
        augmentInvoice(res, processingPayments.payments);

        // If the invoice is paid and has an associated processing payment, 
        // expire the processing payment. 
        if (!res.isPending && !!res.processingPayment) {
            expireProcessingPaymentsByInvoice({ invoice: res, token });
        }

        setIsInvoiceDetailLoading(false);
        setInvoiceDetail(res);
    }

    const handleInvoiceDetailError = (err) => {
        setIsInvoiceDetailLoading(false);
        setHasInvoiceDetailError(true);
    }

    function fetchInvoiceTickets() {
        const { isLoading, isLastPageLoaded, offset, pageSize } = invoiceTickets;
        const invoice = passedInvoiceId || invoiceId;

        if (!isLoading && !isLastPageLoaded) {
            setInvoiceTickets({ ...invoiceTickets, isLoading: true });
            getInvoiceTickets({
                invoiceId: invoice,
                offset,
                limit: pageSize,
                token
            })
                .then(handleInvoiceTicketsSuccess, handleInvoiceTicketsError)
                .catch(handleInvoiceTicketsError);
        }
    }

    const handleInvoiceTicketsSuccess = (res) => {
        const { ticket_count = 0, tickets = [] } = res;
        const isLastPageLoaded = tickets.length < invoiceTickets.pageSize;

        setInvoiceTickets({
            ...invoiceTickets,
            tickets: invoiceTickets.tickets.concat(tickets),
            totalTickets: ticket_count,
            offset: invoiceTickets.offset + tickets.length,
            // After the first smaller page of content, scale up the page size.
            pageSize: 50,
            isLoading: false,
            isFirstPageLoaded: true,
            isLastPageLoaded: isLastPageLoaded,
            isLoadingAll: invoiceTickets.isLoadingAll && !isLastPageLoaded,
        });
    }

    const handleInvoiceTicketsError = (err) => {
        setInvoiceTickets({
            ...invoiceTickets,
            isLoading: false,
            hasError: true,
        });
    }

    function fetchRemainingTicketsAndPrint() {
        const { isLastPageLoaded, isLoadingAll } = invoiceTickets;

        if (!isLoadingAll && !isLastPageLoaded) {
            setInvoiceTickets({
                ...invoiceTickets,
                isLoadingAll: true,
                pageSize: 100,
                printOnLastPage: true,
            });
        }
    }

    //
    // Render Helpers
    // 

    function renderPrintOverlay() {
        const { isLoadingAll } = invoiceTickets;

        return (
            isLoadingAll &&
            <div className="print-loading-overlay">
                <LoadingSpinner typ="page" />
            </div>
        );
    }

    function oPrettyPrice(price) {
        return (!!price && prettyPrice(price)) || '--';
    }

    function formatPriceUnit(price, unit) {
        return oPrettyPrice(price) + (!!price ? ` / ${unit}` : '')
    }

    function renderInvoiceHeader() {
        const { isLastPageLoaded } = invoiceTickets;

        return (
            <Row>
                <h2 className="mb-3 d-flex justify-content-between d-md-none">
                    Invoice
                    <PrintButton onClickFn={isLastPageLoaded ? undefined : fetchRemainingTicketsAndPrint} />
                </h2>
                <p>
                    <strong>Plant:&nbsp;</strong> {getPlantName(invoiceDetail.plant_id, plants)}
                </p>
                <p>
                    <strong>Invoice #:&nbsp;</strong> {invoiceDetail.invoice_number}
                </p>
                <p>
                    <strong>Date:&nbsp;</strong> {formattedDate(invoiceDetail.date)}
                </p>
                <p>
                    <strong>Status:&nbsp;</strong> <InvoiceStatus invoice={invoiceDetail} />
                </p>
                {
                    invoiceDetail.isPending &&
                    <p>
                        <strong>Due Date:&nbsp;</strong> {formattedDate(invoiceDetail.due_date)}
                    </p>
                }
                <p>
                    <strong>Customer:&nbsp;</strong> {formatCustomerNameAndId(invoiceDetail)}
                </p>
                <p>
                    <strong>Order:&nbsp;</strong> {formatOrderDescription(invoiceDetail) || 'N/A'}
                </p>
                <p>
                    <strong>PO Number:&nbsp;</strong> {invoiceDetail.po_number || 'N/A'}
                </p>
            </Row>
        );
    }

    function renderInvoiceTicketsTable() {
        return (
            <Row className="flush-row">
                <Table id="Invoice-Ticket-Table" responsive className="mb-2">
                    <thead className="table-light">
                        <tr>
                            <th>&nbsp;</th>
                            <th>Ticket</th>
                            <th>Date</th>
                            <th>PO Number</th>
                            <th>Order</th>
                            <th>Location</th>
                            <th>Product</th>
                            <th>Qty</th>
                            <th>Material Rate</th>
                            <th>Material Amount</th>
                            <th>Freight Rate</th>
                            <th>Freight Amount</th>
                            <th>Fee Amount</th>
                            <th>Tax Amount</th>
                            <th>Total</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(invoiceTickets.tickets || []).map((ticket) => {
                            return (
                                <tr key={ticket.ticket_number}>
                                    <td>&nbsp;</td>
                                    <td>
                                        {
                                            passedInvoiceId && onTicketClick ?
                                                (<span role="button" className="text-primary" onClick={() => onTicketClick(ticket)}>{ticket.ticket_number}</span>) :
                                                (ticket.ticket_number)}</td>
                                    <td>{formattedDate(ticket.ticket_date)}</td>
                                    <td>{ticket.po_number || "--"}</td>
                                    <td>{formatOrderDescription(ticket) || "--"}</td>
                                    <td>{ticket.location_id || "--"}</td>
                                    <td>{ticket.product_description}</td>
                                    <td className="text-nowrap">{ticket.qty} T</td>
                                    <td>{formatPriceUnit(ticket.material_rate, ticket.material_unit)}</td>
                                    <td>{oPrettyPrice(ticket.price)}</td>
                                    <td>{formatPriceUnit(ticket.freight_rate, ticket.freight_unit)}</td>
                                    <td>{oPrettyPrice(ticket.freight_amount)}</td>
                                    <td>{oPrettyPrice(ticket.fee_amount)}</td>
                                    <td>{oPrettyPrice(ticket.tax_amount)}</td>
                                    <td>{prettyPrice(ticket.total_amount)}</td>
                                    <td>&nbsp;</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {renderInvoiceTicketsTableLoadMore()}
            </Row>
        )
    }

    function renderInvoiceTicketsTableLoadMore() {
        const { isLastPageLoaded, isLoading, offset, pageSize, totalTickets } = invoiceTickets;

        const start = offset;
        const end = Math.min(offset + pageSize, totalTickets);
        const loadMoreMessage = `Load Tickets ${start} - ${end} of ${totalTickets} Total Tickets`;

        return (
            <>
                {!isLastPageLoaded &&
                    <div className="d-flex justify-content-center mb-2">
                        <Button className="pb-0" variant="link" onClick={fetchInvoiceTickets} disabled={isLoading}>
                            {isLoading ? 'Loading...' : loadMoreMessage}
                        </Button>
                    </div>
                }
                <hr className="mt-1" />
            </>
        )
    }

    function renderPaymentButton() {
        return (
            <div className="payment-button-container no-print">
                <PaymentFormButton
                    invoices={[invoiceDetail]}
                    showTermsAndConditions={true}
                    buttonSize="lg"
                />
            </div>
        );
    }

    function renderRemitNotice() {
        return (
            <div className="on-print remit-footer text-center pt-3">
                <p><strong>REMIT TO: MATERIAL SALES CO, INC, PO BOX 60774, CHARLOTTE, NC  28260-0774</strong></p>
            </div>
        )
    }

    function InvoiceAmountRow({ title, amount, ...props }) {
        return (
            <p className={props.className + " d-flex justify-content-between"}>
                <span><strong>{title}: </strong></span>
                <span>{prettyPrice(amount)}</span>
            </p>
        )
    }

    function renderInvoiceDetails() {
        return (
            <>
                {renderPrintOverlay()}
                {renderInvoiceHeader()}
                {renderInvoiceTicketsTable()}
                <InvoiceAmountRow
                    title="Subtotal"
                    amount={invoiceDetail.material_amount}
                    className="text-muted"
                />
                <InvoiceAmountRow
                    title="Freight"
                    amount={invoiceDetail.freight_amount}
                    className="text-muted"
                />
                <InvoiceAmountRow
                    title="Tax"
                    amount={invoiceDetail.tax_amount}
                    className="text-muted"
                />
                {!!invoiceDetail.total_adjustments &&
                    <InvoiceAmountRow
                        title="Adjustments"
                        amount={invoiceDetail.total_adjustments}
                        className="text-warning"
                    />
                }
                <hr />
                <InvoiceAmountRow
                    title="Amount Due"
                    amount={invoiceDetail.total_amount_due}
                    className="fs-5 fw-bold mb-4"
                />

                {renderRemitNotice()}

                {/* TODO: Set correct invoice id. */}
                {
                    invoiceDetail.isPending && renderPaymentButton()
                }
            </>
        );
    }

    return (
        <div id="Invoice-Detail">
            {isInvoiceDetailLoading || !invoiceDetail ?
                <LoadingSpinner typ="page" /> :
                renderInvoiceDetails()
            }
        </div >
    );
};

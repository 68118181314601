import React, {useContext, useEffect, useState} from "react";
import SearchFiltersDesktop, {PAGES} from "../search-filters-input/SearchFiltersDesktop";
import Divider from "@mui/material/Divider";
import {Button, Card, Modal, Spinner, Table} from "react-bootstrap";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import {prettyNumber, prettyPrice} from "../../utils/numberFormatter";
import {DataGridPro} from "@mui/x-data-grid-pro";
import Drawer from "@mui/material/Drawer";
import {downloadTicket, downloadTicketsCsv, getTicketRowCount, getTickets} from "../../apis/ticketsApi";
import {AuthContext} from "../../providers/authProvider";
import {formatQuantity} from "../../utils/ticketFormatter";
import {getPlantName} from "../../constants/plants";
import TicketDetail from "../ticket-detail/TicketDetail";
import {formatArrayFilter, sortByProperty} from "../../utils/miscUtils";
import {IconButton} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import axios from 'axios';
import { PlantsContext } from "../../providers/plantsProvider";

const filtersInitialState = {
    startDate: undefined,
    endDate: undefined,
    plants: undefined,
    search: undefined,
    customers: undefined,
    orders: undefined,
    products: undefined,
    vehicles: undefined,
    carriers: undefined,
}

const TicketHistoryDesktop = () => {
    const {plants: plantsList} = useContext(PlantsContext);


    const [ticketDetail, setTicketDetail] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isRowCountLoading, setIsRowCountLoading] = useState(true);
    const [isCsvDownloadLoading, setCsvDownloadLoading] = useState(false);
    const [isTicketDownloadLoading, setIsTicketDownloadLoading] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [sortModel, setSortModel] = useState([]);
    const [orderBy, setOrderBy] = useState(undefined);
    const [sortOrder, setSortOrder] = useState(undefined);
    const [filters, setFilters] = useState(filtersInitialState);
    const [selectedDateRangeCategory, setSelectedDateRangeCategory] = useState(null);

    const [totalTonnage, setTotalTonnage] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    const [tonnageProducts, setTonnageProducts] = useState([]);
    const [tonnageModalOpen, setTonnageModalOpen] = useState(false);

    const cancelSignal = axios.CancelToken.source();


    const [rows, setRows] = useState([]);
    const drawerWidth = 400;

    const {token} = useContext(AuthContext);

    const handleDownloadTicket = (ticket_number) => {
        setIsTicketDownloadLoading(prevState => ({...prevState, [ticket_number]: true}));
        downloadTicket({ticketId: ticket_number, token})
            .then(() => setIsTicketDownloadLoading(prevState => ({...prevState, [ticket_number]: false})));
    }

    const handlePrintTicketClicked = (e, ticket_number) => {
        e.stopPropagation();
        handleDownloadTicket(ticket_number);
    }

    useEffect(() => {
        let isMounted = true;
        setIsRowCountLoading(true);
        setPageNumber(0);
        setTicketDetail(null);
        getTicketRowCount({
            startDate: filters.startDate || undefined,
            endDate: filters.endDate || undefined,
            searchTerm: filters.search || undefined,
            plants: filters.plants ? formatArrayFilter(filters.plants) : undefined,
            customers: filters.customers ? formatArrayFilter(filters.customers) : undefined,
            orders: filters.orders ? formatArrayFilter(filters.orders) : undefined,
            products: filters.products ? formatArrayFilter(filters.products) : undefined,
            vehicles: filters.vehicles ? formatArrayFilter(filters.vehicles) : undefined,
            carriers: filters.carriers ? formatArrayFilter(filters.carriers) : undefined,
            token,
            cancelToken: cancelSignal.token
        }).then(res => {
            if(isMounted && res) {
                setRowCount(res.ticket_count);
                setTotalTonnage(res.tonnage);
                setTotalProducts(res.num_products);
                setTonnageProducts(res.by_product.sort((a, b) => sortByProperty(a,b, 'tonnage', false)));
                setIsRowCountLoading(false);
            }
        });

        return () => {
            isMounted = false;
            cancelSignal.cancel();
        }
    }, [filters] );

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        getTickets({
            startDate: filters.startDate || undefined,
            endDate: filters.endDate || undefined,
            searchTerm: filters.search || undefined,
            plants: filters.plants ? formatArrayFilter(filters.plants) : undefined,
            customers: filters.customers ? formatArrayFilter(filters.customers) : undefined,
            orders: filters.orders ? formatArrayFilter(filters.orders) : undefined,
            products: filters.products ? formatArrayFilter(filters.products) : undefined,
            vehicles: filters.vehicles ? formatArrayFilter(filters.vehicles) : undefined,
            carriers: filters.carriers ? formatArrayFilter(filters.carriers) : undefined,
            offset: pageNumber * pageSize,
            orderBy,
            sortOrder,
            limit: pageSize,
            token,
            cancelToken: cancelSignal.token
        }).then(res => {
            if(isMounted && res) {
                let tonnage = 0;
                let products = {};
                setRows(res.map((ticket) => {
                    let date = new Date(ticket.ticket_date);
                    return {
                        id: ticket.ticket_id,
                        formattedDate: date.toLocaleDateString('en-US'),
                        customer: `${ticket.customer_name} (#${ticket.customer_id})`,
                        order: ticket.order_id ? `${ticket.order_description_1} (#${ticket.order_id})` : 'N/A',
                        po: ticket.po_number || 'N/A',
                        weight: formatQuantity(ticket),
                        plant: getPlantName(ticket.plant_id, plantsList),
                        ...ticket,
                    }
                }));
                setIsLoading(false);
            }
        });

        return () => {
            isMounted = false;
            cancelSignal.cancel();
        }
    }, [pageSize, pageNumber, sortOrder, orderBy, filters]);


    const handleSortModelChange = (model) => {
        if(model.length > 0) {
            setOrderBy(model[0].field);
            setSortOrder(model[0].sort.toUpperCase())
        } else {
            setOrderBy(undefined);
            setSortOrder(undefined);
        }
        setSortModel(model);
        setTicketDetail(null);
        setPageNumber(0);
    };

    const handleDownloadCsv = () => {
        if(isCsvDownloadLoading) return;
        setCsvDownloadLoading(true);
        downloadTicketsCsv({
            startDate: filters.startDate || undefined,
            endDate: filters.endDate || undefined,
            searchTerm: filters.search || undefined,
            plants: filters.plants ? formatArrayFilter(filters.plants) : undefined,
            customers: filters.customers ? formatArrayFilter(filters.customers) : undefined,
            orders: filters.orders ? formatArrayFilter(filters.orders) : undefined,
            products: filters.products ? formatArrayFilter(filters.products) : undefined,
            vehicles: filters.vehicles ? formatArrayFilter(filters.vehicles) : undefined,
            carriers: filters.carriers ? formatArrayFilter(filters.carriers) : undefined,
            orderBy,
            sortOrder,
            token
        }).then(() => {
            setCsvDownloadLoading(false);
        })
    }

    const columns = [
        {
            field: 'ticket_number',
            headerName: 'Ticket #',
        },
        {
            field: 'formattedDate',
            headerName: 'Date',
        },
        {
            field: 'customer',
            headerName: 'Customer',
            width: 150,
        },
        {
            field: 'order',
            headerName: 'Order',
            width: 150,
        },
        {
            field: 'po',
            headerName: 'PO Number',
        },
        {
            field: 'product_description',
            headerName: 'Product',
            flex: 1,
        },
        {
            field: 'weight',
            headerName: 'Weight',
        },
        {
            field: 'plant',
            headerName: 'Plant',
            width: 150,
        },
        {
            field: 'carrier_name',
            headerName: 'Carrier',
            width: 150,
        },
        {
            field: 'vehicle_id',
            headerName: 'Vehicle ID',
        },
        {
            field: 'print',
            headerName: '',
            renderCell: (params) => {
                return (
                    <Button
                        variant="outline-primary"
                        onClick={(e) => handlePrintTicketClicked(e, params.row.ticket_id)}
                        disabled={isTicketDownloadLoading[params.row.ticket_id]}
                    >
                        {
                            isTicketDownloadLoading[params.row.ticket_id] ?
                            (
                                <Spinner animation='border' className="icon-spinner"  />
                            ) :
                            (
                                <i
                                    className="bi bi-printer"
                                    style={{fontSize: 16, paddingRight: 6}}
                                    role="img"
                                    aria-label="print button"
                                />
                            )
                        }

                        <span>Print</span>
                    </Button>)

            },
            align: "center",
            sortable: false,
        }
    ];

    return (
        <>
            <div className={'main ' + (!!ticketDetail || tonnageModalOpen ? 'drawer-open': '') + ' d-flex flex-row'} style={{height: "100%"}}>
                <div style={{minWidth: 300, maxWidth: 300}}>
                    <SearchFiltersDesktop
                        page={PAGES.Tickets}
                        setFilters={setFilters}
                        selectedDateRangeCategory={selectedDateRangeCategory}
                        setSelectedDateRangeCategory={setSelectedDateRangeCategory}
                    />
                </div>
                <Divider orientation="vertical" flexItem style={{height: "100%"}}/>
                <div className="d-flex flex-column p-2" style={{width: "100%"}}>
                    <Card className="mb-3" style={{width: "70%"}}>
                        <Card.Body>
                            {isRowCountLoading ? (
                                <div className="d-flex flex-column justify-content-center" style={{height: "100%"}}>
                                    <LoadingSpinner typ="infinite_scroll" />
                                </div>
                            ) : (
                                <div className="d-flex flex-row justify-content-between">
                                    <div>
                                        <h5 className="text-muted text-black-50">Total Tonnage</h5>
                                        <h3>{prettyNumber(totalTonnage, 0)} T</h3>
                                    </div>
                                    <div>
                                        <h5 className="text-muted text-black-50"># of Tickets</h5>
                                        <h3>{rowCount}</h3>
                                    </div>
                                    <div>
                                        <h5 className="text-muted text-black-50"># of Products</h5>
                                        <h3>{totalProducts}</h3>
                                    </div>
                                    <span>
                                        <Button
                                            className="p-3"
                                            variant="outline-primary"
                                            onClick={handleDownloadCsv}
                                            disabled={isCsvDownloadLoading}
                                        >
                                            { isCsvDownloadLoading ? (<Spinner animation="border" style={{height: 16, width: 16}}/>) : "Export to CSV"}
                                        </Button>
                                        <Button
                                            className="mx-2 p-3"
                                            variant="outline-primary"
                                            onClick={() => setTonnageModalOpen(true)}
                                        >Tonnage Summary</Button>
                                    </span>
                                </div>
                            )}

                        </Card.Body>
                    </Card>
                    <div style={{width: "100%", minHeight: "60%", flexGrow: 1, overflow: "auto"}}>
                        <DataGridPro
                            loading={isLoading}
                            paginationMode="server"
                            sortMode="server"
                            pagination
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                            rows={isLoading ? [] : rows}
                            columns={columns}
                            onPageChange={(page => setPageNumber(page))}
                            page={pageNumber}
                            pageSize={pageSize}
                            rowCount={isRowCountLoading ? 0 : rowCount}
                            onPageSizeChange={(size) => setPageSize(size)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnFilter={true}
                            disableColumnSelector={true}
                            disableMultipleColumnsSorting={true}
                            onRowClick={row => {
                                setTicketDetail(row);
                                setTonnageModalOpen(false);
                            }}
                            sx={{
                                backgroundColor: "white"
                            }}
                        />
                    </div>
                </div>
            </div>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box"
                    },
                }}
                variant="persistent"
                anchor="right"
                open={!!ticketDetail || tonnageModalOpen}
            >
                {tonnageModalOpen ? (
                    <>
                        <h2 className="p-4 mb-4 pb-0 d-flex justify-content-between">
                                Tonnage Summary
                                <i
                                    onClick={() => {
                                        setTicketDetail(null);
                                        setTonnageModalOpen(false);
                                    }}
                                    className="bi bi-x"
                                    role="img"
                                    aria-label="Navigation Menu"
                                />
                        </h2>
                        <div className="overflow-auto p-2">
                            { isRowCountLoading ? (
                                <LoadingSpinner typ="infinite-scroll" />
                            ) : (
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th className="text-right">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tonnageProducts.map(product => (
                                            <tr>
                                                <td>{product.product}</td>
                                                <td className="text-right">{formatQuantity({
                                                    qty: product.tonnage,
                                                    unit: 'Tons'
                                                })}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}

                        </div>
                    </>) :
                    (
                        <>
                            <h2 className="p-4 mb-4 pb-0 d-flex justify-content-between">
                                <span>Ticket
                                    {
                                        isTicketDownloadLoading[ticketDetail?.id] ?
                                            (
                                                <Spinner animation="border" className="icon-spinner mx-2" />
                                            ) : (
                                                <IconButton size="small" onClick={() => handleDownloadTicket(ticketDetail.id)}>
                                                    <FileDownloadOutlinedIcon fontSize="small" />
                                                </IconButton>
                                            )
                                    }
                                </span>
                                <i
                                    onClick={() => {
                                        setTicketDetail(null);
                                    }}
                                    className="bi bi-x"
                                    role="img"
                                    aria-label="Navigation Menu"
                                />
                            </h2>
                            <div className="p-4 pt-1 overflow-auto">
                                {!!ticketDetail && (
                                    <>
                                        <TicketDetail passedTicket={ticketDetail}/>
                                    </>
                                )}
                            </div>
                        </>
                )}

            </Drawer>
        </>
    );
}

export default TicketHistoryDesktop;

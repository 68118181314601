import React, {useState, useEffect, useContext} from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';

import './SearchFiltersModal.scss';

import { getPlantName } from '../../constants/plants';

import { formattedDate, getFilterDateRanges } from '../../utils/dateFormatter';
import { PAGES, sortPlants } from '../search-filters-input/SearchFiltersDesktop';
import {getDesktopInvoiceFilters} from "../../apis/invoicesApi";
import {sortByProperty} from "../../utils/miscUtils";
import {getDesktopTicketFilters} from "../../apis/ticketsApi";
import {AuthContext} from "../../providers/authProvider";
import { PlantsContext } from '../../providers/plantsProvider';

export default function SearchFilterModal({
    initFilters,
    show,
    onHide,
    onApplyFilters,
    onClearFilters,
    title,
    page = PAGES.Invoices,
    ...props
}) {
    const { token } = useContext(AuthContext);
    const [filters, setFilters] = useState(initFilters || {});
    const [isLoadingFilters, setIsLoadingFilters] = useState(false);

    const [plants, setPlants] = useState([]);
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);

    const setPlantId = (plantId) => setFilters({ ...filters, plantId });
    const setDateRange = (dateRange) => setFilters({ ...filters, dateRange });
    const setSelectedProduct = (product) => setFilters({...filters, product});
    const setSelectedOrder = (order) => setFilters({...filters, order});

    const {plants: plantsList} = useContext(PlantsContext);

    useEffect(() => {
        if (!!show) {
            setFilters(initFilters || {});
        }
    }, [show]);

    useEffect(() => {
        let isMounted = true;
        setIsLoadingFilters(true);
        if(page === PAGES.Invoices) {
            getDesktopInvoiceFilters({token}).then(res => {
                if(isMounted) {
                    setPlants(res.plants.sort((a,b) => sortPlants(a,b, plantsList)));
                    setIsLoadingFilters(false);
                }
            })
        } else if(page === PAGES.Tickets) {
            getDesktopTicketFilters({token}).then(res => {
                if(isMounted) {
                    console.log(res);
                    setPlants(res.plants.sort((a,b) => sortPlants(a,b, plantsList)));
                    setOrders(res.orders.filter(order => !!order.order_description).sort((a, b) => sortByProperty(a, b, 'order_description')));
                    setProducts(res.products.sort());
                    setIsLoadingFilters(false);
                }
            })
        }

        return () => {
            isMounted = false;
        }
    }, [page]);

    // 
    // Render Helpers
    // 

    function renderDateRangeFilter() {
        const dateRanges = getFilterDateRanges();
        const { dateRange } = filters;

        return (
            <Dropdown className="mb-3 d-grid">
                <Dropdown.Toggle variant="outline-secondary" size="lg">
                    {dateRange.name || "Select a Date Range"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        dateRanges.map((range) => {
                            return (
                                <Dropdown.Item onClick={() => setDateRange(range)} key={range.name}>
                                    {range.name}&nbsp;
                                    {(range.rangeType === 'week') &&
                                        <>
                                            ({formattedDate(range.startDate)} - {formattedDate(range.endDate)})
                                        </>
                                    }
                                </Dropdown.Item>
                            )
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    function renderPlantFilter() {
        const { plantId } = filters;

        return (
            <Dropdown className="d-grid">
                <Dropdown.Toggle variant="outline-secondary" size="lg">
                    {plantId ? getPlantName(plantId, plantsList) : "Select a Plant"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    { !isLoadingFilters ?
                        (plants.map((plant) => {
                            return (
                                <Dropdown.Item onClick={() => setPlantId(plant)} key={plant}>
                                    {getPlantName(plant, plantsList)}
                                </Dropdown.Item>
                            )
                        })) :
                        (
                            <Dropdown.Item disabled>
                                Loading...
                            </Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    function renderProductFilter() {
        const {product} = filters;
        return (
            <Dropdown className="mt-3 d-grid">
                <Dropdown.Toggle variant="outline-secondary" size="lg">
                    {product || "Select a Product"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    { !isLoadingFilters ?
                        (products.map((product) => {
                            return (
                                <Dropdown.Item onClick={() => setSelectedProduct(product)} key={product}>
                                    {product}
                                </Dropdown.Item>
                            )
                        })) :
                        (
                            <Dropdown.Item disabled>
                                Loading...
                            </Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    function renderOrderFilter() {
        const {order} = filters;
        return (
            <Dropdown className="mt-3 d-grid">
                <Dropdown.Toggle variant="outline-secondary" size="lg">
                    {orders.find(o => o.order_id === order)?.order_description || "Select an Order"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    { !isLoadingFilters ?
                        (orders.map((order) => {
                            return (
                                <Dropdown.Item onClick={() => setSelectedOrder(order.order_id)} key={order.order_id}>
                                    {order.order_description}
                                </Dropdown.Item>
                            )
                        })) :
                        (
                            <Dropdown.Item disabled>
                                Loading...
                            </Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    return (
        <Modal
            id="Search-Filters-Modal"
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title || "Filter Results"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {renderDateRangeFilter()}
                {renderPlantFilter()}
                {page === PAGES.Tickets && renderProductFilter()}
                {page === PAGES.Tickets && renderOrderFilter()}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="primary" size="lg" onClick={() => onApplyFilters(filters)}>
                    Apply Filters
                </Button>
                <Button variant="outline-secondary" size="lg" onClick={onClearFilters}>
                    Clear Filters
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

import axios from "axios";
import { BASE_API_URL } from "./baseApi";

const authAxios = axios.create({
  baseURL: `${BASE_API_URL}/auth`,
  withCredentials: true, // Required to send cookies
});

export const refreshInterceptor = (error) => {
  if (error.response?.status === 401) {
    return refreshJwtToken().then((res) => {
      error.config.headers["Authorization"] = "Bearer " + res;
      return axios.request(error.config);
    });
  }

  return Promise.reject(error);
};

function handleAccessTokenSuccess(res) {
  const { access: accessToken } = res.data;

  if (!accessToken) {
    // TODO: Error handling
  }

  return accessToken;
}

/**
 * Creates a new JWT access token for a given customer.
 *
 * @param username  The customer's username.
 * @param password  The customer's password.
 *
 * @returns A new JWT access token.
 */
export async function getJwtToken({ username, password }) {
  return authAxios
    .post(`/token/`, {
      email: username,
      password: password,
    })
    .then(handleAccessTokenSuccess);
}

/**
 * Attempt to refresh the current JWT access token.
 * Uses a "refresh" token saved as an httpOnly cookie.
 *
 * @returns A new JWT access token.
 */
export async function refreshJwtToken() {
  return authAxios
    .post(`/token/refresh/`)
    .then(handleAccessTokenSuccess)
    .catch((err) => {
      // Ingore "Unauthorized" errors.
      // This means that no refresh token is stored as a cookie.
      // Otherwise, log the error.
      if (err.response?.status !== 401) {
        console.error(err);
      }
    });
}

/**
 * Clear the current JWT refresh token.
 *
 * @returns
 */
export async function removeJwtToken() {
  return authAxios.post(`/token/remove/`);
}

/**
 * Fetches details for the current user.
 *
 * @returns User details.
 */
export async function getAuthorizedUser(token) {
  return authAxios
    .get(`/user/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    });
}

/**
 * Signs up a new user, and generates a JWT access token.
 *
 * @param firstName         The new user's first name.
 * @param lastName          The new user's last name.
 * @param emailAddress      The new user's email address (used a unique username).
 * @param password          The new user's password.
 * @param invoiceNumber     The invoice number from the user's onboarding invoice.
 * @param customerNumber    The customer number from the user's onboarding invoice.
 * @param plantId           The plant id from the user's onboarding invoice.
 *
 * @returns A new JWT access token.
 */
export async function createAndAuthorizeUser({
  firstName,
  lastName,
  emailAddress,
  password,
  invoiceNumber,
  customerNumber,
  plantId,
}) {
  return authAxios
    .post("/users/", {
      first_name: firstName,
      last_name: lastName,
      email: emailAddress,
      password: password,
      invoice_number: invoiceNumber,
      customer_number: customerNumber,
      plant_id: plantId,
    })
    .then(handleAccessTokenSuccess);
}

/**
 * Verifies invoice details to match a user to a plant-specific customer profile.
 *
 * @param invoiceNumber     The invoice number from the user's invoice.
 * @param customerNumber    The customer number from the user's invoice.
 * @param plantId           The plant id from the user's invoice.
 *
 * @returns A boolean to indicate whether the invoice details are valid.
 */
export async function verifyCustomerProfile({
  invoiceNumber,
  customerNumber,
  plantId,
}) {
  return authAxios
    .post("/users/customer/verify/", {
      invoice_number: invoiceNumber,
      customer_number: customerNumber,
      plant_id: plantId,
    })
    .then(
      (res) => {
        return true;
      },
      (err) => {
        return false;
      }
    );
}

/**
 * Verifies invoice details to match a user to a plant-specific customer profile,
 * and associates that customer profile to the logged in user.
 *
 * @param invoiceNumber     The invoice number from the user's invoice.
 * @param customerNumber    The customer number from the user's invoice.
 * @param plantId           The plant id from the user's invoice.
 *
 * @returns
 */
export async function addCustomerProfile({
  invoiceNumber,
  customerNumber,
  plantId,
  token,
}) {
  return authAxios.post(
    "/users/customer/",
    {
      invoice_number: invoiceNumber,
      customer_number: customerNumber,
      plant_id: plantId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

/**
 * Retrieves a list of customers associated with the current user.
 *
 * @returns
 */
export async function getCustomerProfiles({ token }) {
  return authAxios
    .get("/users/customers/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export async function sendResetPasswordEmail({ email }) {
  return authAxios.post("/forgot-password/", { email }).then((res) => {
    return res.data;
  });
}

export async function resetPassword({
  email,
  uid,
  resetToken,
  password,
  confirmPassword,
}) {
  return authAxios
    .post("/reset-password/", {
      email,
      uid,
      resetToken,
      password,
      confirmPassword,
    })
    .then((res) => res.data);
}

export async function updatePassword({ token, old_password, new_password }) {
  return authAxios
    .put(
      "/update-password/",
      {
        old_password,
        new_password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);
}

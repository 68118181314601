import { Spinner } from 'react-bootstrap';

import './LoadingSpinner.scss'

export default function LoadingSpinner({ typ }) {
    function calculateLoadingSpinnerClass() {
        switch (typ) {
            case 'page': return 'page-loading-spinner';
            case 'infinite-scroll': return 'infinite-scroll-loading-spinner';
            default: return '';
        }
    }

    return (
        <div className="text-center">
            <Spinner
                className={calculateLoadingSpinnerClass()}
                animation="border" />
        </div>
    );
};

import React, {useContext, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import {RadioGroup, Radio} from "@mui/material";
import TextField from '@mui/material/TextField';

import {getDesktopInvoiceFilters} from "../../apis/invoicesApi";
import {getDesktopTicketFilters} from "../../apis/ticketsApi";
import {AuthContext} from "../../providers/authProvider";
import {getPlantName} from "../../constants/plants";
import {getFilterDateRanges} from "../../utils/dateFormatter";

import './SearchFiltersDesktop.scss';
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import {IconButton, InputAdornment} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {sortByProperty} from "../../utils/miscUtils";
import axios from "axios";
import { PlantsContext } from '../../providers/plantsProvider';

export const PAGES = {
    Invoices: "invoices",
    Tickets: "tickets"
}

export const sortPlants = (a, b, plantsList) => {
    if(getPlantName(a, plantsList) > getPlantName(b, plantsList)) {
        return 1;
    } else if (getPlantName(a, plantsList) < getPlantName(b, plantsList)) {
        return -1;
    }
    return 0;
}

const SearchFiltersDesktop = ({page = PAGES.Invoices, setFilters, selectedDateRangeCategory, setSelectedDateRangeCategory}) => {
    const dateRanges = getFilterDateRanges();
    const [invoiceStatus, setInvoiceStatus] = useState("DUE");
    const [plants, setPlants] = useState([]);
    const [isLoadingFilters, setIsLoadingFilters] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [selectedCarriers, setSelectedCarriers] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedPlants, setSelectedPlants] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [search, setSearch] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const {token} = useContext(AuthContext);
    const {plants: plantsList} = useContext(PlantsContext);
    const cancelSignal = axios.CancelToken.source();

    const formatSelectedCustomersFilter = () =>
        selectedCustomers.reduce((acc, curr) => {
            return acc.concat(curr.ids);
        }, []);


    useEffect(() => {
        if(page === PAGES.Invoices) {
            const newFilters = {
                status: invoiceStatus,
                startDate: startDate ? startDate.toLocaleDateString() : null,
                endDate: endDate ? endDate.toLocaleDateString() : null,
                plants: selectedPlants,
                search,
                customers: formatSelectedCustomersFilter(),
                orders: selectedOrders.map(order => order.order_id),
            }

            setFilters(newFilters);
        } else if(page === PAGES.Tickets) {
            const newFilters = {
                startDate: startDate ? startDate.toLocaleDateString() : null,
                endDate: endDate ? endDate.toLocaleDateString() : null,
                plants: selectedPlants,
                search,
                customers: formatSelectedCustomersFilter(),
                orders: selectedOrders.map(order => order.order_id),
                products: selectedProducts,
                vehicles: selectedVehicles,
                carriers: selectedCarriers,
            }

            setFilters(newFilters);
        }
    }, [
        selectedPlants,
        selectedCustomers,
        selectedOrders,
        selectedProducts,
        selectedVehicles,
        selectedCarriers,
        invoiceStatus,
        startDate,
        endDate,
        search,
        setFilters
    ]);



    function formatCustomersFilterOptions(customers) {
        return customers.reduce((acc, curr) => {
            if(!acc[curr.customer_name]) {
                acc[curr.customer_name] = {
                    customer_name: curr.customer_name,
                    ids: [curr.customer_id]
                }
            } else {
                acc[curr.customer_name].ids.push(curr.customer_id);
            }
            return acc;
        }, {});
    }


    useEffect(() => {
        let isMounted = true;
        setIsLoadingFilters(true);
        if(page === PAGES.Invoices) {
            getDesktopInvoiceFilters({token, cancelToken: cancelSignal.token}).then(res => {
                if(isMounted && res) {
                    setPlants(res.plants.sort((a,b) => sortPlants(a,b, plantsList)));
                    setCustomers(Object.values(formatCustomersFilterOptions(res.customers)).filter(customer => customer !== null).sort((a, b) => sortByProperty(a, b, 'customer_name')));
                    setOrders(res.orders.filter(order => !!order.order_description).filter(order => order !== null).sort((a, b) => sortByProperty(a, b, 'order_description')));
                    setIsLoadingFilters(false);
                }
            })
        } else if(page === PAGES.Tickets) {
            getDesktopTicketFilters({token, cancelToken: cancelSignal.token}).then(res => {
                if(isMounted && res) {
                    setPlants(res.plants.sort((a,b) => sortPlants(a,b, plantsList)));
                    setCustomers(Object.values(formatCustomersFilterOptions(res.customers)).filter(customer => customer !== null).sort((a, b) => sortByProperty(a, b, 'customer_name')));
                    setOrders(res.orders.filter(order => !!order.order_description).filter(order => order !== null).sort((a, b) => sortByProperty(a, b, 'order_description')));
                    setProducts(res.products.filter(product => product !== null).sort());
                    setVehicles(res.vehicles.filter(vehicle => vehicle !== null).sort());
                    setCarriers(res.carriers.filter(carrier => carrier !== null).sort());
                    setIsLoadingFilters(false);
                }
            })
        }

        return () => {
            isMounted = false;
            cancelSignal.cancel();
        }
    }, [page]);

    useEffect(() => {
        if(selectedDateRangeCategory?.name !== 'Custom') {
            setStartDate(selectedDateRangeCategory?.startDate || null);
            setEndDate(selectedDateRangeCategory?.endDate || null);
        }
    }, [selectedDateRangeCategory])

    const dateOptions = [
        {
            name: 'Custom'
        },
        ...dateRanges
    ];

    const clearAllFilters = () => {
        setSelectedPlants([]);
        setSelectedCustomers([]);
        setSelectedDateRangeCategory(null);
        setStartDate(null);
        setEndDate(null);
        setSelectedOrders([]);
        setSelectedProducts([]);
        setSelectedVehicles([]);
        setSelectedCarriers([]);
        setInvoiceStatus("DUE");
    }

    const checkSearchEnter = (event) => {
        if(event.keyCode === 13) {
            setSearch(searchInput);
        }
    }

    function renderSearchBar(placeholder) {
        return (
            <>
                <h4>Search</h4>
                <FormControl className="mt-3" placeholder="Search by Invoice, PO, or Order #">
                    <TextField
                        variant="filled"
                        size="small"
                        label={placeholder}
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                        onBlur={() => setSearch(searchInput)}
                        type="search"
                        onKeyDown={(event) => checkSearchEnter(event)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            'endAdornment': (
                            <InputAdornment position="end">
                                <IconButton aria-label="search" onClick={() => setSearch(searchInput)} edge="end">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                    />
                </FormControl>
            </>
        )
    }

    function renderDateFilters() {
        return (
            <>
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={dateOptions}
                        getOptionLabel={(option) => option.name || ''}
                        isOptionEqualToValue={(option, value) => {
                            return option?.name === value?.name;
                        }}
                        value={selectedDateRangeCategory}
                        onChange={(event, newValue) => setSelectedDateRangeCategory(newValue)}
                        renderInput={(params) => <TextField {...params} label="Date Range" />}
                        sx={{backgroundColor: 'white'}}
                    />
                </FormControl>
                { selectedDateRangeCategory?.name == 'Custom' && (
                    <>
                        <FormControl className="mt-3">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Start Date"
                                    renderInput={(params) => <TextField variant="filled" {...params} />}
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    clearable
                                    minDate={new Date('2015/01/01')}
                                    maxDate={endDate || new Date()}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl className="mt-3">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="End Date"
                                    renderInput={(params) => <TextField variant="filled" {...params} />}
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    clearable
                                    minDate={startDate || new Date('2015/01/01')}
                                    maxDate={new Date()}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </>
                )
                }
            </>
        )
    }

    function renderInvoiceFilters() {
        return (
            <>
                {renderSearchBar("Search by Invoice, PO, or Order #")}
                <h4 className="mt-5 d-flex flex-row justify-content-between">Filters <Button variant="link" onClick={clearAllFilters}>Clear All</Button> </h4>

                <FormControl className="mt-2">
                    <FormLabel>Invoice Status</FormLabel>
                    <RadioGroup
                        defaultValue="DUE"
                        name="radio-buttons-group"
                        className="d-flex flex-row"
                        value={invoiceStatus}
                        onChange={e => setInvoiceStatus(e.target.value)}
                    >
                        <FormControlLabel value="DUE" control={<Radio />} label="Due" labelPlacement="start" />
                        <FormControlLabel value="PAID" control={<Radio />} label="Paid" labelPlacement="start" />
                        <FormControlLabel value="" control={<Radio />} label="Both" labelPlacement="start" />
                    </RadioGroup>
                </FormControl>

                {renderDateFilters()}
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={plants}
                        getOptionLabel={(option) => getPlantName(option, plantsList) || ''}
                        value={selectedPlants}
                        onChange={(event, newValue) => setSelectedPlants(newValue)}
                        renderInput={(params) => <TextField {...params} label="Plants" />}
                        sx={{backgroundColor: 'white'}}
                        multiple
                        loading={isLoadingFilters}
                    />
                </FormControl>
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={customers}
                        getOptionLabel={(option) => option.customer_name || ''}
                        value={selectedCustomers}
                        onChange={(event, newValue) => setSelectedCustomers(newValue)}
                        renderInput={(params) => <TextField {...params} label="Customers" />}
                        sx={{backgroundColor: 'white'}}
                        multiple
                        loading={isLoadingFilters}
                    />
                </FormControl>
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={orders}
                        getOptionLabel={(option) => option.order_description ? `${option.order_description} (${option.order_id})` : 'N/A'}
                        value={selectedOrders}
                        onChange={(event, newValue) => setSelectedOrders(newValue)}
                        renderInput={(params) => <TextField {...params} label="Orders" />}
                        sx={{backgroundColor: 'white'}}
                        multiple
                        loading={isLoadingFilters}
                    />
                </FormControl>
            </>
        );
    }

    function renderTicketFilters() {
        return (
            <>
                {renderSearchBar("Search by Ticket, PO, Order #, or Product")}
                <h4 className="mt-5 d-flex flex-row justify-content-between">Filters <Button variant="link" onClick={clearAllFilters}>Clear All</Button> </h4>
                {renderDateFilters()}
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={plants}
                        getOptionLabel={(option) => getPlantName(option, plantsList) || ''}
                        value={selectedPlants}
                        onChange={(event, newValue) => setSelectedPlants(newValue)}
                        renderInput={(params) => <TextField {...params} label="Plants" />}
                        sx={{backgroundColor: 'white'}}
                        multiple
                        loading={isLoadingFilters}
                    />
                </FormControl>
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={customers}
                        getOptionLabel={(option) => option.customer_name || ''}
                        value={selectedCustomers}
                        onChange={(event, newValue) => setSelectedCustomers(newValue)}
                        renderInput={(params) => <TextField {...params} label="Customers" />}
                        sx={{backgroundColor: 'white'}}
                        multiple
                        loading={isLoadingFilters}
                    />
                </FormControl>
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={orders}
                        getOptionLabel={(option) => option.order_description ? `${option.order_description} (${option.order_id})` : 'N/A'}
                        value={selectedOrders}
                        onChange={(event, newValue) => setSelectedOrders(newValue)}
                        renderInput={(params) => <TextField {...params} label="Orders" />}
                        sx={{backgroundColor: 'white'}}
                        multiple
                        loading={isLoadingFilters}
                    />
                </FormControl>
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={products}
                        value={selectedProducts}
                        onChange={(event, newValue) => setSelectedProducts(newValue)}
                        renderInput={(params) => <TextField {...params} label="Products" />}
                        sx={{backgroundColor: 'white'}}
                        multiple
                        loading={isLoadingFilters}
                    />
                </FormControl>
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={carriers}
                        value={selectedCarriers}
                        onChange={(event, newValue) => setSelectedCarriers(newValue)}
                        renderInput={(params) => <TextField {...params} label="Carriers" />}
                        sx={{backgroundColor: 'white'}}
                        multiple
                        loading={isLoadingFilters}
                    />
                </FormControl>
                <FormControl className="mt-3">
                    <Autocomplete
                        disablePortal
                        options={vehicles}
                        value={selectedVehicles}
                        onChange={(event, newValue) => setSelectedVehicles(newValue)}
                        renderInput={(params) => <TextField {...params} label="Vehicles" />}
                        sx={{backgroundColor: 'white'}}
                        multiple
                        loading={isLoadingFilters}
                    />
                </FormControl>
            </>
        );
    }

    function renderFilters() {
        switch(page) {
            case PAGES.Invoices:
                return renderInvoiceFilters();
            case PAGES.Tickets:
                return renderTicketFilters();
            default:
                return renderInvoiceFilters();
        }
    }


    return (
        <div className="p-3 flex-column overflow-auto" style={{height: '100%'}}>
            {renderFilters()}
        </div>
    );
}

export default SearchFiltersDesktop;
